import { Button, Form, Input, message, Modal, Table } from 'antd'
import type { ColumnType } from 'antd/es/table'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { BsKey, BsPersonDown, BsPersonUp } from 'react-icons/bs'
import { IoCheckmarkCircleOutline, IoCloseCircleOutline } from 'react-icons/io5'
import { MdSearch } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'

import { ROLE } from '@/common/enum'
import { randomString } from '@/common/utils'
import type { RootState } from '@/store'

import { activateUser, changeRole, disableUser, getListUser, resetPassword, setFilter } from './reducers/userReducer'

const UserManager: React.FC = () => {
  const dispatch = useDispatch<any>()
  const items = useSelector((state: RootState) => state.users.items)
  const userRole = useSelector((state: RootState) => state.auth.user?.role)
  const [modalPassword, setModalPassword] = useState<boolean>(false)
  const [idReset, setIdReset] = useState<number>(-1)
  const [loading, setLoading] = useState<boolean>(true)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const query = useSelector((state: RootState) => state.users.query)
  const [search, setSearch] = useState<string>(query || '')

  const [form] = Form.useForm()

  const fectchData = async () => {
    setLoading(true)
    try {
      await dispatch(getListUser())
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleActive = async (id: number) => {
    try {
      await dispatch(activateUser(id))
    } catch (error) {}
  }

  const handleDisable = async (id: number) => {
    try {
      await dispatch(disableUser(id))
    } catch (error) {}
  }

  const changeRoleUser = async (id: number) => {
    try {
      await dispatch(changeRole(id))
    } catch (error) {}
  }

  const handleResetClick = (id: number) => {
    setIdReset(id)
    setModalPassword(true)
  }

  const handleResetPassword = async () => {
    try {
      setSubmitting(true)
      const { password } = await form.validateFields()

      const rs = await dispatch(resetPassword({ id: idReset, password })).unwrap()
      if (rs) {
        setModalPassword(false)
        setIdReset(-1)
        message.success('Đã gửi email thông báo đặt lại mật khẩu')
      }
    } catch (error) {
    } finally {
      setSubmitting(false)
    }
  }

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: 'Email',
      key: 'email',
      render: (record: any) => record?.doctor?.email
    },
    {
      title: 'Loại tài khoản',
      key: 'role',
      render: (text: any, record: any) => <span>{record.role === ROLE.ADMIN ? 'Quản trị' : 'Thành viên'}</span>
    },
    {
      title: 'Trạng thái',
      key: 'isDel',
      render: (text: any, record: any) => <span>{record.isDel ? 'Vô hiệu hoá' : 'Hoạt động'}</span>
    },
    userRole === ROLE.SUPERADMIN && {
      title: 'Action',
      key: 'action',
      width: 250,
      render: (text: any, record: any) => (
        <div className='flex gap-2'>
          <Button
            type='default'
            danger={!record.isDel}
            onClick={() => (record.isDel ? handleActive(record.id) : handleDisable(record.id))}
            title={record.isDel ? 'Kích hoạt' : 'Vô hiệu hoá tài khoản'}
          >
            {record.isDel ? <IoCheckmarkCircleOutline size={20} /> : <IoCloseCircleOutline size={20} />}
          </Button>
          {record.role === ROLE.ADMIN && (
            <Button
              type='default'
              title='Bỏ quyền Admin'
              onClick={() => changeRoleUser(record.id)}
              className='border-yellow-500 text-yellow-500 hover:border-yellow-400 hover:text-yellow-400'
            >
              <BsPersonDown size={20} />
            </Button>
          )}

          {record.role === ROLE.DOCTOR && (
            <Button type='primary' title='Đặt làm Admin' onClick={() => changeRoleUser(record.id)}>
              <BsPersonUp size={20} />
            </Button>
          )}

          <Button type='default' title='Đặt lại mật khẩu' onClick={() => handleResetClick(record.id)}>
            <BsKey size={20} />
          </Button>
        </div>
      )
    }
  ].filter(Boolean) as ColumnType<any>[]

  const handleSearch = useCallback(
    debounce((text: string) => {
      dispatch(setFilter(text))
    }, 500),
    []
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value
    setSearch(text)
    handleSearch(text)
  }

  useEffect(() => {
    form.setFieldsValue({
      password: randomString(8)
    })

    fectchData()
  }, [query])

  return (
    <div>
      <Modal
        title='Đặt lại mật khẩu'
        open={modalPassword}
        onCancel={() => {
          setModalPassword(false)
          setIdReset(-1)
        }}
        onOk={handleResetPassword}
        confirmLoading={submitting}
        okText='Xác nhận'
      >
        <div className='flex gap-2'>
          <Form form={form} className='mt-5 flex-1' autoComplete='off' requiredMark={false}>
            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập mật khẩu'
                },
                {
                  min: 6,
                  message: 'Mật khẩu phải có ít nhất 6 ký tự'
                }
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Form>
          <Button
            onClick={() =>
              form.setFieldsValue({
                password: randomString(8)
              })
            }
            type='default'
            htmlType='button'
            className='mt-5'
          >
            Random
          </Button>
        </div>
      </Modal>

      <div className='mb-4 flex justify-end'>
        <Input className='w-[250px]' value={search} onChange={handleChange} placeholder='Tìm kiếm tài khoản' prefix={<MdSearch />} />
      </div>
      <Table dataSource={items} columns={columns} rowKey='id' loading={loading} />
    </div>
  )
}

export default UserManager
