import { Form, Input, message, Modal } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { addDepartment, fetchDepartments } from '../../reducers/doctorReducer'

const AddDepartment: React.FC = () => {
  const dispatch = useDispatch<any>()
  const navigate: NavigateFunction = useNavigate()
  const [form] = Form.useForm()

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()

      const rs = await dispatch(
        addDepartment({
          name: values.dname,
          description: values.description
        })
      ).unwrap()

      if (rs) {
        message.success('Phòng ban đã được thêm thành công')
        form.resetFields()
        dispatch(fetchDepartments())
        navigate('/doctors/add-doctor')
      }
    } catch (error) {}
  }

  const onCancel = () => {
    navigate('/doctors/add-doctor')
  }

  return (
    <Modal title='Thêm phòng ban' open onCancel={onCancel} onOk={handleSubmit} okText='Thêm'>
      <Form form={form} layout='vertical'>
        <Form.Item name='dname' label='Tên phòng ban' rules={[{ required: true, message: 'Vui lòng nhập tên phòng ban' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='description' label='Mô tả'>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddDepartment
