import React from 'react'

import Account from './Account'

export const AccountRoutes = [
  {
    path: 'account',
    element: <Account />
  }
]
