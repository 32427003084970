import React from 'react'
import { useSelector } from 'react-redux'

import type { RootState } from '@/store'

import type { MedicalFormState } from '../../common/constant'

const Page5: React.FC = () => {
  const data: MedicalFormState = useSelector((state: RootState) => state.medical.detail)

  if (!data) return <div>Không có dữ liệu</div>

  return (
    <div className='w-full'>
      <h3 className='mb-2'>Tóm tắt bệnh án:</h3>
      <p className='ps-4'>{data?.tomtat || 'Không có'}</p>

      <h3 className='mb-2'>Chẩn đoán:</h3>
      <div className='mb-1 ps-3'>Bệnh lý mô mềm và mô nha chu:</div>
      <p className='mt-2 ps-8'>{data?.chandoan1 || 'Không có'}</p>

      <div className='mb-1 mt-3 ps-3'>Bệnh lý mô cứng:</div>
      <p className='mt-2 ps-8'>{data?.chandoan2 || 'Không có'}</p>

      <div className='mb-1 mt-3 ps-3'>Bệnh lý tuỷ và vùng quanh chóp:</div>
      <p className='mt-2 ps-8'>{data?.chandoan3 || 'Không có'}</p>

      <div className='mb-1 mt-3 ps-3'>Tình trạng mất răng:</div>
      <p className='mt-2 ps-8'>{data?.chandoan4 || 'Không có'}</p>

      <div className='mb-1 mt-3 ps-3'>Tình trạng khớp cắn:</div>
      <p className='mt-2 ps-8'>{data?.chandoan5 || 'Không có'}</p>
    </div>
  )
}

export default Page5
