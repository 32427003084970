import { Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import type { RootState } from '@/store'

import type { MedicalFormState } from '../../common/constant'
import { fetchListDoctor, setDoctorId, updateDataState } from '../../reducers/medicalReducer'

const { Option } = Select

const Page1: React.FC = () => {
  const dispatch = useDispatch<any>()
  const dataState: MedicalFormState = useSelector((state: RootState) => state.medical.dataState)
  const [data, setData] = useState<any>({
    ...dataState
  })

  const [doctors, setDoctors] = useState<any[]>([])
  const selectedDoctor = useSelector((state: RootState) => state.medical.doctorId)

  const fetchDoctors = async () => {
    try {
      const rs = await dispatch(fetchListDoctor()).unwrap()

      if (rs && rs.items) {
        setDoctors(rs.items)
      }
    } catch (error) {}
  }

  const handleInputChange = (setter: any, field: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, checked, value } = e.target

    const newValue = type === 'checkbox' ? checked : value

    setter((prevState: any) => {
      if (field) {
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [name]: newValue
          }
        }
      }
      return {
        ...prevState,
        [name]: newValue
      }
    })
  }

  useEffect(() => {
    fetchDoctors()
  }, [])

  useEffect(() => {
    dispatch(updateDataState(data))
  }, [data, dispatch])

  return (
    <div>
      <p className='text-lg font-bold'>KHOA RĂNG HÀM MẶT, ĐH Y Dược TP Hồ Chí Minh</p>
      <p className='mr-2'>
        <span>Số phiếu điều trị:</span>
        <input type='text' name='code' value={data.code} onChange={handleInputChange(setData, null)} />
      </p>
      <div className='my-2'>
        <span>Sinh viên theo dõi điều trị:</span>
        <Select
          style={{ width: 200 }}
          placeholder='Select a doctor'
          value={selectedDoctor}
          onChange={(value) => {
            dispatch(setDoctorId(value))
            setData((prevState: any) => ({
              ...prevState,
              student: doctors.find((doctor) => doctor.id === value)?.name
            }))
          }}
          allowClear
        >
          {doctors.map((doctor) => (
            <Option key={doctor.id} value={doctor.id}>
              {doctor.name}
            </Option>
          ))}
        </Select>
      </div>
      <h1 className='mt-6 text-center text-2xl font-bold'>THÔNG TIN BỆNH NHÂN</h1>

      <table className='mt-4 w-full table-fixed border-collapse'>
        <tbody>
          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <div className='flex'>
                <span>Họ và Tên:</span>
                <input type='text' name='name' className='flex-1' value={data.patient.name} onChange={handleInputChange(setData, 'patient')} />
              </div>
            </td>
            <td className='border border-black/50 px-2 py-1'>
              Ngày sinh:
              <input type='text' name='dob' value={data.patient.dob} onChange={handleInputChange(setData, 'patient')} />
            </td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <div className='flex'>
                <span>Nghề nghiệp:</span>
                <input type='text' name='job' className='flex-1' value={data.patient.job} onChange={handleInputChange(setData, 'patient')} />
              </div>
            </td>
            <td className='border border-black/50 px-2 py-1'>
              Trình độ văn hóa:
              <input type='text' name='education' value={data.patient.education} onChange={handleInputChange(setData, 'patient')} />
            </td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>
              Giới tính:
              <input type='text' name='gender' value={data.patient.gender} onChange={handleInputChange(setData, 'patient')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              Dân tộc/Quốc tịch:
              <input type='text' name='nation' value={data.patient.nation} onChange={handleInputChange(setData, 'patient')} />
            </td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>
              Liên hệ (SĐT/Email/…):
              <input type='text' name='contact' value={data.patient.contact} onChange={handleInputChange(setData, 'patient')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              Nơi cư ngụ:
              <input type='text' name='address' value={data.patient.address} onChange={handleInputChange(setData, 'patient')} />
            </td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>
              Ngày khám đầu tiên:
              <input type='text' name='firstExam' value={data.patient.firstExam} onChange={handleInputChange(setData, 'patient')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              Lý do đến khám:
              <input type='text' name='reason' value={data.patient.reason} onChange={handleInputChange(setData, 'patient')} />
            </td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>
              Người liên lạc trong trường hợp khẩn cấp:
              <input type='text' name='contactPerson' value={data.patient.contactPerson} onChange={handleInputChange(setData, 'patient')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              Bệnh sử:
              <input type='text' name='history' value={data.patient.history} onChange={handleInputChange(setData, 'patient')} />
            </td>
          </tr>
        </tbody>
      </table>

      <p className='mt-6 font-bold'>Vấn đề toàn thân</p>
      <p className='mt-2'>
        Cân nặng:
        <input type='text' name='weight' className='w-10' value={data.weight} onChange={handleInputChange(setData, null)} />
        kg
      </p>
      <p className='mt-2'>
        Chiều cao:
        <input type='text' name='height' className='w-10' value={data.height} onChange={handleInputChange(setData, null)} />
        cm
      </p>

      <table className='mt-4 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Dị ứng</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='diung' checked={data.sick.diung} onChange={handleInputChange(setData, 'sick')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Mang thai</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='mangthai' checked={data.sick.mangthai} onChange={handleInputChange(setData, 'sick')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Cao huyết áp</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='caohuyetap' checked={data.sick.caohuyetap} onChange={handleInputChange(setData, 'sick')} />
            </td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Bệnh bẩm sinh</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='bamsinh' checked={data.sick.bamsinh} onChange={handleInputChange(setData, 'sick')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Cho con bú</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='choconbu' checked={data.sick.choconbu} onChange={handleInputChange(setData, 'sick')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Đái tháo đường</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='daithaoduong' checked={data.sick.daithaoduong} onChange={handleInputChange(setData, 'sick')} />
            </td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Hút thuốc lá</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='hutthuoc' checked={data.sick.hutthuoc} onChange={handleInputChange(setData, 'sick')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Từng bị ngất xỉu</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='bingatxiu' checked={data.sick.bingatxiu} onChange={handleInputChange(setData, 'sick')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Bệnh tim</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='benhtim' checked={data.sick.benhtim} onChange={handleInputChange(setData, 'sick')} />
            </td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Rượu bia</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='ruou' checked={data.sick.ruou} onChange={handleInputChange(setData, 'sick')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Viêm khớp dạng thấp</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='viemkhop' checked={data.sick.viemkhop} onChange={handleInputChange(setData, 'sick')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Bệnh máu</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='benhmau' checked={data.sick.benhmau} onChange={handleInputChange(setData, 'sick')} />
            </td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Mang máy điều hòa nhịp tim</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='maydieuhoa' checked={data.sick.maydieuhoa} onChange={handleInputChange(setData, 'sick')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Viêm gan siêu vi A, B, C</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='viemgan' checked={data.sick.viemgan} onChange={handleInputChange(setData, 'sick')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Bệnh thận</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='benhthan' checked={data.sick.benhthan} onChange={handleInputChange(setData, 'sick')} />
            </td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Có stent mạch máu</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='stentmachmau' checked={data.sick.stentmachmau} onChange={handleInputChange(setData, 'sick')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Lao</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='lao' checked={data.sick.lao} onChange={handleInputChange(setData, 'sick')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Bệnh suyễn</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='benhsuyen' checked={data.sick.benhsuyen} onChange={handleInputChange(setData, 'sick')} />
            </td>
          </tr>
        </tbody>
      </table>

      <p className='mt-6 font-bold'>Vấn đề tâm lý - hành vi - xã hội</p>
      <table className='mt-4 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Khó tiếp cận/ khó thuyết phục</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='khotiepcan' checked={data.tamly.khotiepcan} onChange={handleInputChange(setData, 'tamly')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Yêu cầu thẩm mỹ thái quá</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='yeucauthammy' checked={data.tamly.yeucauthammy} onChange={handleInputChange(setData, 'tamly')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Vấn đề về tài chính</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='vandetaichinh' checked={data.tamly.vandetaichinh} onChange={handleInputChange(setData, 'tamly')} />
            </td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Khó hợp tác/không tuân thủ điều trị</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='khohoptac' checked={data.tamly.khohoptac} onChange={handleInputChange(setData, 'tamly')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Hành vi tự làm hại</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='tulamhai' checked={data.tamly.tulamhai} onChange={handleInputChange(setData, 'tamly')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Lo âu, sợ hãi</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='loausohai' checked={data.tamly.loausohai} onChange={handleInputChange(setData, 'tamly')} />
            </td>
          </tr>
        </tbody>
      </table>

      <p className='mt-6 font-bold'>Tiền sử nha khoa</p>
      <table className='mt-4 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='border border-black/50 px-2 py-1' colSpan={4}>
              <div className='flex'>
                <span>Những lần khám răng gần đây, lý do:</span>
                <input type='text' name='reasonHistory' className='flex-1' value={data.dental.reasonHistory} onChange={handleInputChange(setData, 'dental')} />
              </div>
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1 font-bold'>Chăm sóc răng miệng</td>
            <td className='border border-black/50 px-2 py-1 text-center'> </td>
            <td className='border border-black/50 px-2 py-1'>Dùng tăm tre</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='dungtam' checked={data.dental.dungtam} onChange={handleInputChange(setData, 'dental')} />
            </td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Khám răng miệng định kỳ</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='khamdinhky' checked={data.dental.khamdinhky} onChange={handleInputChange(setData, 'dental')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Dùng chỉ nha khoa</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='dungchi' checked={data.dental.dungchi} onChange={handleInputChange(setData, 'dental')} />
            </td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Chải răng hằng ngày</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='chaihangngay' checked={data.dental.chaihangngay} onChange={handleInputChange(setData, 'dental')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Dùng bàn chải kẽ</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='dungbanchai' checked={data.dental.dungbanchai} onChange={handleInputChange(setData, 'dental')} />
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1' colSpan={4}>
              <div className='flex'>
                <span>Cách chải răng hiện nay (số lần, cách thức, loại kem đánh răng):</span>
                <input type='text' name='cachchairang' className='flex-1' value={data.dental.cachchairang} onChange={handleInputChange(setData, 'dental')} />
              </div>
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1 font-bold'>Chế độ ăn (tần suất)</td>
            <td className='border border-black/50 px-2 py-1'> </td>
            <td className='border border-black/50 px-2 py-1'> </td>
            <td className='border border-black/50 px-2 py-1'> </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>Thường ăn thực phẩm cứng</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='anthucphamcung' checked={data.dental.anthucphamcung} onChange={handleInputChange(setData, 'dental')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Thường ăn/uống thực phẩm chua</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='anthucphamchua' checked={data.dental.anthucphamchua} onChange={handleInputChange(setData, 'dental')} />
            </td>
          </tr>

          <tr className='h-[1px]'>
            <td className='border border-black/50 px-2 py-1'>Thường ăn/uống thực phẩm ngọt</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='anthucphamngot' checked={data.dental.anthucphamngot} onChange={handleInputChange(setData, 'dental')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Thường uống nước uống có ga</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='uongnuocgacoga' checked={data.dental.uongnuocgacoga} onChange={handleInputChange(setData, 'dental')} />
            </td>
          </tr>
        </tbody>
      </table>

      <p className='mt-6 font-bold'>Thói quen liên quan tình trạng răng miệng</p>
      <table className='mt-4 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Nhai một bên (phải / trái)</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='nhaimotben' checked={data.thoiquenrang.nhaimotben} onChange={handleInputChange(setData, 'thoiquenrang')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Cắn môi/ má</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='canmoi' checked={data.thoiquenrang.canmoi} onChange={handleInputChange(setData, 'thoiquenrang')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Nghiến răng khi ngủ</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input
                type='checkbox'
                name='nghienrangkhingu'
                checked={data.thoiquenrang.nghienrangkhingu}
                onChange={handleInputChange(setData, 'thoiquenrang')}
              />
            </td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Nhai vật cứng</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='nhaivatcung' checked={data.thoiquenrang.nhaivatcung} onChange={handleInputChange(setData, 'thoiquenrang')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Tật đẩy lưỡi</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='tatdayluoi' checked={data.thoiquenrang.tatdayluoi} onChange={handleInputChange(setData, 'thoiquenrang')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Nghiến chặt răng khi thức</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='nghienchatrang' checked={data.thoiquenrang.nghienchatrang} onChange={handleInputChange(setData, 'thoiquenrang')} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Page1
