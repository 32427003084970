import { Button, Input, message, Table, Tooltip } from 'antd'
import type { ColumnType } from 'antd/es/table'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { MdSearch } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { ROLE } from '@/common/enum'
import type { RootState } from '@/store'

import { getDoctors, setFilter, updateHeadDoctor } from './reducers/doctorReducer'

interface Doctor {
  id: number
  name: string
  specialization: string
  phoneNumber: string
  email: string
  departmentId: number
  department?: any
  isHead: boolean
  isDel: boolean
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

const DoctorManager: React.FC = () => {
  const dispatch = useDispatch<any>()
  const navigate: NavigateFunction = useNavigate()
  const items = useSelector((state: RootState) => state.doctor.items) as Doctor[]
  const [loading, setLoading] = useState<boolean>(true)
  const currentUser = useSelector((state: RootState) => state.auth.user)
  const normalDoctor = currentUser?.role === ROLE.DOCTOR && currentUser?.doctor?.isHead === false

  const query = useSelector((state: RootState) => state.doctor.query)
  const [search, setSearch] = useState<string>(query || '')

  const fectchData = async () => {
    setLoading(true)
    try {
      await dispatch(getDoctors())
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const setHeadDoctor = async (doctorId: number) => {
    try {
      await dispatch(updateHeadDoctor({ doctorId, setHead: true }))
      message.success('Đã đặt bác sĩ làm trưởng khoa')
    } catch (error) {}
  }

  const demoteHeadDoctor = async (doctorId: number) => {
    try {
      await dispatch(updateHeadDoctor({ doctorId, setHead: false }))
      message.success('Đã hủy bác sĩ làm trưởng khoa')
    } catch (error) {}
  }

  const handleNavigateAdd = () => {
    const isHeadDoctor = currentUser?.role === ROLE.DOCTOR && currentUser?.doctor?.isHead
    if (isHeadDoctor) {
      navigate('add-member')
    } else {
      navigate('add-doctor')
    }
  }

  const handleSearch = useCallback(
    debounce((text: string) => {
      dispatch(setFilter(text))
    }, 500),
    []
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value
    setSearch(text)
    handleSearch(text)
  }

  useEffect(() => {
    fectchData()
  }, [query])

  const columns = [
    {
      title: 'Tên bác sĩ',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Username',
      key: 'user',
      render: (record: any) => record.user?.username
    },
    {
      title: 'Chuyên ngành',
      dataIndex: 'specialization',
      key: 'specialization',
      width: '120px',
      render: (text: string) => (
        <Tooltip
          title={text?.length > 15 ? text : ''}
          overlayInnerStyle={{
            minWidth: 'max-content'
          }}
        >
          <span className='block w-[120px] truncate'>{text}</span>
        </Tooltip>
      )
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '150px',
      render: (text: string) => (
        <Tooltip
          title={text}
          overlayInnerStyle={{
            minWidth: 'max-content'
          }}
        >
          <span className='block w-[150px] truncate'>{text}</span>
        </Tooltip>
      )
    },
    {
      title: 'Phòng ban',
      key: 'department',
      render: (record: Doctor) => record.department?.name
    },
    {
      title: 'Chức vụ',
      dataIndex: 'isHead',
      key: 'isHead',
      render: (value: boolean) => (value ? 'Trưởng khoa' : 'Bác sĩ')
    },
    !(currentUser.role === ROLE.DOCTOR) && {
      title: 'Action',
      key: 'action',
      render: (text: any, record: Doctor) => (
        <Button type='primary' danger={record.isHead} onClick={() => (record.isHead ? demoteHeadDoctor(record.id) : setHeadDoctor(record.id))}>
          {record.isHead ? 'Demote' : 'Set Head'}
        </Button>
      )
    }
  ].filter(Boolean) as ColumnType<any>[]

  return (
    <div>
      <div className='mb-4 flex justify-end gap-2'>
        <Input className='w-[250px]' value={search} onChange={handleChange} placeholder='Tìm kiếm bác sĩ' prefix={<MdSearch />} />

        {!normalDoctor && (
          <Button type='primary' onClick={handleNavigateAdd}>
            Thêm bác sĩ
          </Button>
        )}
      </div>

      <Table dataSource={items} columns={columns} rowKey='id' loading={loading} rowClassName='cursor-pointer' />
    </div>
  )
}

export default DoctorManager
