import React from 'react'

import UserManager from './UserManager'

export const AuthorizeRoutes = [
  {
    path: 'authorizations',
    element: <UserManager />
  }
]
