import { Button, Divider, Form, Input, message } from 'antd'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import type { RootState } from '@/store'

import { login } from './reducers/authReducer'

const LoginPage: FC = () => {
  const [messageApi, contextHolder] = message.useMessage()

  const dispatch = useDispatch<any>()
  const navigate: NavigateFunction = useNavigate()
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)

  /*
   *****************************************
   *
   *
   */

  const handleLogin = async (username: string, password: string) => {
    try {
      await dispatch(login({ username, password })).unwrap()
      navigate('/')
    } catch (error: any) {
      if (error?.message) {
        messageApi.error(error.message)
      } else {
        messageApi.error('Something went wrong!')
      }
    }
  }

  const onFinish = async (values: any) => {
    handleLogin(values.username, values.password)
  }

  /*
   *****************************************
   *
   *
   */

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/')
    }
  }, [])

  return (
    <div className='flex min-h-screen w-full flex-col items-center overflow-y-auto bg-[#f8f8f8] py-6 md:py-16'>
      {contextHolder}

      <div className='px flex w-full max-w-full flex-col gap-4 px-4 md:w-[420px]'>
        <div className='flex flex-col rounded-lg border border-primary-200 bg-white px-4 py-5 shadow md:px-8 md:pb-12 md:pt-8'>
          <div className='text-3xl font-semibold text-base-1 md:text-4xl'>Sign in</div>
          <div className='mt-4 text-sm font-semibold text-base-1 md:mt-6'>Sign in with open account</div>

          <Divider dashed className='my-4' />

          <div className=''>
            <Form layout='vertical' onFinish={onFinish}>
              <Form.Item label='Username' name='username' rules={[{ required: true, message: 'Please input your username!' }]} className='mb-4'>
                <Input />
              </Form.Item>

              <Form.Item label='Password' name='password' rules={[{ required: true, message: 'Please input your password!' }]} className='mb-4'>
                <Input type='password' />
              </Form.Item>

              <Form.Item>
                <Button type='primary' htmlType='submit' className='w-full'>
                  Sign in
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
