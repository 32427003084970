/* eslint-disable @typescript-eslint/no-unused-vars */
import type { GetProp, UploadFile, UploadProps } from 'antd'
import { App, Button, Image, Upload } from 'antd'
import React, { useState } from 'react'
import { IoAdd } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate, useSearchParams } from 'react-router-dom'

import type { RootState } from '@/store'

import { type MedicalFormState } from '../../common/constant'
import { createMedicalRecord, uploadMedicalFiles } from '../../reducers/medicalReducer'
import Page1 from './Page1'
import Page2 from './Page2'
import Page3 from './Page3'
import Page4 from './Page4'
import Page5 from './Page5'
import Page6 from './Page6'
import Page7 from './Page7'
import Page8 from './Page8'
import Page9 from './Page9'
import Page10 from './Page10'

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

const AddLayout: React.FC = () => {
  const dispatch = useDispatch<any>()
  const [searchParams] = useSearchParams()
  const { message } = App.useApp()
  const page = searchParams.get('page') || '1'
  const navigate: NavigateFunction = useNavigate()
  const totalPages = 10
  const data: MedicalFormState = useSelector((state: RootState) => state.medical.dataState)
  const doctorId = useSelector((state: RootState) => state.medical.doctorId)
  const createdBy = useSelector((state: RootState) => state.auth.user?.doctor?.name || state.auth.user?.username) as string
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
  }

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileList(newFileList)

  const beforeUpload = (file: FileType) => {
    const isAllowedType = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/bmp', 'image/jpg', 'video/mp4'].includes(file.type)

    if (!isAllowedType) {
      message.error('Chỉ cho phép các định dạng JPEG, PNG, GIF, WEBP, BMP, JPG và MP4!')
      return Upload.LIST_IGNORE
    }

    const isImage = file.type.startsWith('image')
    const maxSize = isImage ? 10 : 35
    const isWithinLimit = file.size / 1024 / 1024 < maxSize

    if (!isWithinLimit) {
      message.error(isImage ? 'Dung lượng ảnh phải nhỏ hơn 10MB!' : 'Dung lượng video phải nhỏ hơn 35MB!')
      return Upload.LIST_IGNORE
    }

    setFileList((list) => [...list, file])
    return false
  }

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      <IoAdd />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  )

  const goToPage = (pageNumber: string) => {
    if (pageNumber === page) return
    navigate(`/medical-records/add-medical?page=${pageNumber}`)
  }

  const handleCreate = async () => {
    try {
      const rs = await dispatch(
        createMedicalRecord({
          data,
          doctorId,
          createdBy
        })
      ).unwrap()

      const medicalId = rs.id

      if (fileList.length > 0) {
        const formData = new FormData()
        fileList.forEach((file) => {
          formData.append('files', file.originFileObj as File)
        })

        formData.append('medicalId', medicalId)

        await dispatch(uploadMedicalFiles(formData)).unwrap()
      }

      navigate(`/medical-records/${rs.id}?page=1`)
      message.success('Tạo hồ sơ thành công')
    } catch (error) {
      message.error('Đã xảy ra lỗi khi tạo hồ sơ hoặc upload file')
    }
  }

  const pages = Array.from({ length: totalPages }, (_, i) => i + 1)

  const renderPage = () => {
    switch (page) {
      case '1':
        return <Page1 />
      case '2':
        return <Page2 />
      case '3':
        return <Page3 />
      case '4':
        return <Page4 />
      case '5':
        return <Page5 />
      case '6':
        return <Page6 />
      case '7':
        return <Page7 />
      case '8':
        return <Page8 />
      case '9':
        return <Page9 />
      case '10':
        return <Page10 />
      default:
        return <Page1 />
    }
  }

  return (
    <div className='form-medical'>
      <div className='flex justify-between'>
        <div className='flex justify-center space-x-4'>
          {pages.map((pageNumber) => (
            <Button
              key={pageNumber}
              onClick={() => goToPage(pageNumber.toString())}
              type={page === pageNumber.toString() ? 'primary' : 'default'}
              className='w-8'
            >
              {pageNumber}
            </Button>
          ))}
        </div>
        <Button type='primary' onClick={handleCreate}>
          Lưu hồ sơ
        </Button>
      </div>

      <div className='mt-4'>
        <Upload action='' listType='picture-card' fileList={fileList} onPreview={handlePreview} onChange={handleChange} beforeUpload={beforeUpload}>
          {fileList.length >= 5 ? null : uploadButton}
        </Upload>
        {previewImage && (
          <Image
            wrapperStyle={{ display: 'none' }}
            preview={{
              visible: previewOpen,
              onVisibleChange: (visible) => setPreviewOpen(visible),
              afterOpenChange: (visible) => !visible && setPreviewImage('')
            }}
            src={previewImage}
          />
        )}
      </div>

      {renderPage()}
    </div>
  )
}

export default AddLayout
