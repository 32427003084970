import { customAlphabet } from 'nanoid'

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

export const getNanoId = (length = 4): string => {
  const nanoid = customAlphabet(alphabet, length)
  return nanoid()
}

export const genCode = (): string => {
  const now = new Date()

  const timeZoneOffset = now.getTimezoneOffset()

  if (timeZoneOffset === -420) {
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const day = String(now.getDate()).padStart(2, '0')
    const year = now.getFullYear().toString().slice(-2)

    return `${year}${month}${day}${getNanoId(4)}`
  }

  const utcDate = new Date(now.getTime())
  const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0')
  const day = String(utcDate.getUTCDate()).padStart(2, '0')
  const year = utcDate.getUTCFullYear().toString().slice(-2)

  return `${year}${month}${day}${getNanoId(4)}`
}
