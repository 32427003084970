import { differenceInMinutes, format, isSameDay, isSameHour, isSameYear, isToday, isYesterday } from 'date-fns'
import { isEqual, isNaN } from 'lodash'

export function formatSize(num: number): string {
  if (num >= 1e6) {
    return `${(num / 1e6).toLocaleString('en-US', { maximumFractionDigits: 1 })}M`
  }
  if (num >= 1e3) {
    return `${(num / 1e3).toLocaleString('en-US', { maximumFractionDigits: 1 })}K`
  }

  return num.toLocaleString('en-US')
}

export function formatNumber(number: number): string {
  const formattedNumber = number.toLocaleString('en-US', {
    maximumFractionDigits: 0
  })

  return formattedNumber
}

export function formatMoney(amount: number, suffix = 'đ'): string {
  const formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND'
  })

  const formattedAmount = formatter.format(amount)
  return formattedAmount.replace('₫', suffix).trim()
}

export function base64toFile(base64String: string, filename: string): File {
  const parts = base64String.split(';') as any
  const contentType = parts[0].split(':')[1]
  const raw = window.atob(parts[1].split(',')[1])

  const fileData = new Uint8Array(raw.length)
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < raw.length; ++i) {
    fileData[i] = raw.charCodeAt(i)
  }

  const file = new File([fileData], filename, { type: contentType })
  return file
}

/*
 *****************************************
 *
 *
 */

export function getDefaultProfileAvatar(): string {
  return '/images/default/avatar-1.png'
}

export function getUpdatedDiff(originalObj: any, updatedObj: any): any {
  const diff = Object.keys(updatedObj).reduce((diffInfo, key) => {
    if (isEqual(originalObj[key], updatedObj[key])) {
      return diffInfo
    }

    return {
      ...diffInfo,
      [key]: updatedObj[key]
    }
  }, {})

  return diff
}

/*
 *****************************************
 * Enum
 *
 */

export function convertEnumToArray(enumObj: any): any[] {
  return Object.keys(enumObj)
    .filter((k) => isNaN(Number(k)))
    .map((key) => ({
      value: enumObj[key],
      label: key
    }))
}

export function convertEnumToSelectOptions(enumObj: any): any[] {
  return Object.keys(enumObj)
    .filter((k) => isNaN(Number(k)))
    .map((key) => ({
      value: enumObj[key],
      label: key
        .split('_')
        .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
        .join(' ')
    }))
}

export function getEnumKeyName(enumObj: any, enumVal: any): any[] {
  const arr = convertEnumToSelectOptions(enumObj)

  const obj = arr.find((item) => item.value === enumVal)

  return obj.label
}

/*
 *****************************************
 *
 *
 */

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

/*
 *****************************************
 *
 *
 */

export function randomString(length: number, masks = ['lowercase', 'uppercase', 'numbers']) {
  let mask = ''

  if (masks.includes('lowercase')) {
    mask += 'abcdefghijklmnopqrstuvwxyz'
  }

  if (masks.includes('uppercase')) {
    mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  }

  if (masks.includes('numbers')) {
    mask += '0123456789'
  }

  if (masks.includes('symbols')) {
    // mask += '~`!@#$%^&*()_-={}[]:";\'<>?,./|\\'
    mask += '!@#$%^&*?'
  }

  if (mask === '') {
    return ''
  }

  let result = ''

  for (let i = length; i > 0; --i) {
    result += mask[Math.floor(Math.random() * mask.length)]
  }
  return result
}

/*
 *****************************************
 * Format Time
 *
 */

export function formatTime(date: Date, formatString?: string): string {
  if (formatString) {
    return format(date, formatString)
  }

  if (isToday(date)) {
    return `${format(date, 'hh:mm')}`
  }

  if (isYesterday(date)) {
    return `Hôm qua ${format(date, 'hh:mm')}`
  }

  if (isSameYear(date, new Date())) {
    return `${format(date, 'dd')} tháng ${format(date, 'MM hh:mm')}`
  }

  return format(date, 'dd/MM/yyyy')
}

export function formatTimeAgo(date: Date): string {
  const now = new Date()
  const inputDate = new Date(date)

  if (isSameDay(now, inputDate)) {
    if (isSameHour(now, inputDate)) {
      const minutesDiff = differenceInMinutes(now, inputDate)
      return minutesDiff < 2 ? 'Just now' : `${minutesDiff} minutes ago`
    }
    return `Today at ${format(inputDate, 'HH:mm')}`
  }
  return format(inputDate, 'HH:mm dd/MM/yyyy')
}

export function formatToVietnameseDate(isoString: string): string {
  const date = new Date(isoString)

  const adjustedTime = new Date(date.getTime() - 7 * 60 * 60 * 1000)

  return adjustedTime.toLocaleString('vi-VN', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
}
