import { Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import type { RootState } from '@/store'

import type { MedicalFormState } from '../../common/constant'
import { updateEditState } from '../../reducers/medicalReducer'

const { TextArea } = Input

const Page5: React.FC = () => {
  const dispatch = useDispatch<any>()
  const dataState: MedicalFormState = useSelector((state: RootState) => state.medical.editState)
  const [data, setData] = useState<MedicalFormState>({
    ...dataState
  })

  const handleInputChange = (setter: any, field: any) => async (e: any) => {
    const { name, type, checked, value } = e.target

    const newValue = type === 'checkbox' ? checked : value

    await setter((prevState: any) => {
      if (field) {
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [name]: newValue
          }
        }
      }
      return {
        ...prevState,
        [name]: newValue
      }
    })
  }

  useEffect(() => {
    dispatch(updateEditState(data))
  }, [data, dispatch])

  return (
    <div className='w-full'>
      <h3 className='mb-2'>Tóm tắt bệnh án:</h3>
      <TextArea
        placeholder='Thêm tóm tắt...'
        autoSize={{
          minRows: 8
        }}
        value={data.tomtat}
        name='tomtat'
        onChange={handleInputChange(setData, null)}
      />

      <h3 className='mb-2'>Chẩn đoán:</h3>
      <div className='mb-1 ps-3'>Bệnh lý mô mềm và mô nha chu:</div>
      <TextArea
        placeholder='Thêm chẩn đoán...'
        autoSize={{
          minRows: 4
        }}
        value={data.chandoan1}
        name='chandoan1'
        onChange={handleInputChange(setData, null)}
      />

      <div className='mb-1 mt-3 ps-3'>Bệnh lý mô cứng:</div>
      <TextArea
        placeholder='Thêm chẩn đoán...'
        autoSize={{
          minRows: 4
        }}
        value={data.chandoan2}
        name='chandoan2'
        onChange={handleInputChange(setData, null)}
      />

      <div className='mb-1 mt-3 ps-3'>Bệnh lý tuỷ và vùng quanh chóp:</div>
      <TextArea
        placeholder='Thêm chẩn đoán...'
        autoSize={{
          minRows: 4
        }}
        value={data.chandoan3}
        name='chandoan3'
        onChange={handleInputChange(setData, null)}
      />

      <div className='mb-1 mt-3 ps-3'>Tình trạng mất răng:</div>
      <TextArea
        placeholder='Thêm chẩn đoán...'
        autoSize={{
          minRows: 4
        }}
        value={data.chandoan4}
        name='chandoan4'
        onChange={handleInputChange(setData, null)}
      />

      <div className='mb-1 mt-3 ps-3'>Tình trạng khớp cắn:</div>
      <TextArea
        placeholder='Thêm chẩn đoán...'
        autoSize={{
          minRows: 4
        }}
        value={data.chandoan5}
        name='chandoan5'
        onChange={handleInputChange(setData, null)}
      />
    </div>
  )
}

export default Page5
