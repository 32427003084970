import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '@/common/api'

type AccountState = {}

const initialState: AccountState = {}

export const updateProfilePic = createAsyncThunk('profile/updateAvatar', async (payload: FormData, { rejectWithValue }) => {
  try {
    const rs = await api.post('profile/upload', payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    if (rs) {
      return rs
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const accountSlice = createSlice({
  name: 'account',

  initialState,

  reducers: {},

  extraReducers: (_builder) => {}
})

// export const { updateDataState, resetDataState, updateEditState, setDoctorId, setFilter } = medicalSlice.actions
export default accountSlice.reducer
