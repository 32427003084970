import React from 'react'
import { useSelector } from 'react-redux'

import type { RootState } from '@/store'

import type { MedicalFormState } from '../../common/constant'

const Page9: React.FC = () => {
  const data: MedicalFormState = useSelector((state: RootState) => state.medical.detail)

  if (!data) return <div>Không có dữ liệu</div>

  return (
    <div className='w-full'>
      <h3 className='text-xl'>TỔNG KẾT SAU ĐIỀU TRỊ</h3>
      <table className='mt-4 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='border border-black/50 px-2 py-1 text-center'>Số buổi hẹn dự kiến</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Số buổi hẹn thực tế</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Lý do phát sinh</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Xác nhận của mentor</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1 text-center'>{data.page9?.row1col1 || '_'}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data.page9?.row1col2 || '_'}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data.page9?.row1col3 || '_'}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data.page9?.row1col4 || '_'}</td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1 text-center'>Tổng chi phí dự kiến</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Tổng chi phí thực tế</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Lý do phát sinh</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Xác nhận của mentor</td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1 text-center'>{data.page9?.row2col1 || '_'}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data.page9?.row2col2 || '_'}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data.page9?.row2col3 || '_'}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data.page9?.row2col4 || '_'}</td>
          </tr>
        </tbody>
      </table>
      <h3 className='text-xl'>TỔNG KẾT SAU ĐIỀU TRỊ</h3>
      <div className='mt-3'>Các yếu tố ảnh hưởng đến kế hoạch điều trị đã được đưa ra:</div>
      <p className='mt-2 ps-4'>{data.page9?.note1}</p>

      <div className='mt-3'>Những khó khăn/vấn đề dẫn đến thay đổi kế hoạch điều trị hay ảnh hưởng kết quả điều trị:</div>
      <p className='mt-2 ps-4'>{data.page9?.note2}</p>

      <div className='mt-3'>Những kĩ năng mới học được/cải thiện được sau theo dõi điều trị ca này:</div>
      <p className='mt-2 ps-4'>{data.page9?.note3}</p>

      <div className='mt-3'>Nếu được thay đổi việc điều trị, bạn muốn thay đổi:</div>
      <p className='mt-2 ps-4'>{data.page9?.note4}</p>
    </div>
  )
}

export default Page9
