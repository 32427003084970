import React from 'react'
import { useSelector } from 'react-redux'

import type { RootState } from '@/store'

import type { MedicalFormState } from '../../common/constant'

const Page6: React.FC = () => {
  const data: MedicalFormState = useSelector((state: RootState) => state.medical.detail)

  if (!data) return <div>Không có dữ liệu</div>

  return (
    <div className='w-full'>
      <div className='mt-3 flex items-center justify-between'>
        <div className='flex flex-col text-lg'>
          <span>ĐẠI HỌC Y DƯỢC TPHCM</span>
          <span className='font-bold'>KHOA RĂNG HÀM MẶT</span>
        </div>
        <div className='w-1/3'>
          <div className='flex gap-1'>
            <span>Họ tên bệnh nhân:</span>
            <span>{data.patient?.name}</span>
          </div>
          <div className='flex gap-1'>
            <span>Số hồ sơ:</span>
            <span>{data?.code}</span>
          </div>
        </div>
      </div>
      <h3 className='mb-2 text-center'>KẾ HOẠCH ĐIỀU TRỊ</h3>

      <table className='mt-4 w-full border-collapse'>
        <tbody>
          <tr>
            <td colSpan={3} className='border border-black/50 px-2 py-1 text-center text-base font-bold'>
              Kiểm soát tổng trạng
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1 text-center'>Yếu tố toàn thân cần lưu ý</td>
            <td colSpan={2} className='border border-black/50 px-2 py-1 text-center'>
              Xử trí
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>{data.row1?.luuy || '...'}</td>
            <td colSpan={2} className='border border-black/50 px-2 py-1'>
              {data.row1?.xutri || '...'}
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>{data.row2?.luuy || '...'}</td>
            <td colSpan={2} className='border border-black/50 px-2 py-1'>
              {data.row2?.xutri || '...'}
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>{data.row3?.luuy || '...'}</td>
            <td colSpan={2} className='border border-black/50 px-2 py-1'>
              {data.row3?.xutri || '...'}
            </td>
          </tr>

          <tr>
            <td colSpan={3} className='border border-black/50 px-2 py-1 text-center text-base font-bold'>
              Điều trị khẩn
            </td>
          </tr>

          {/* Điều trị khẩn */}
          <tr>
            <td className='w-[70%] border border-black/50 px-2 py-1 text-center'>Loại hình điều trị</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Chi phí dự kiến</td>
            <td className='w-[10%] border border-black/50 px-2 py-1 text-center'>Số buổi dự kiến</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>{data.row4?.loai || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row4?.gia || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row4?.sobuoi || '...'}</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>{data.row5?.loai || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row5?.gia || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row5?.sobuoi || '...'}</td>
          </tr>

          <tr>
            <td colSpan={3} className='border border-black/50 px-2 py-1 text-center text-base font-bold'>
              Kiểm soát bệnh & phòng ngừa
            </td>
          </tr>

          {/* Kiểm soát bệnh & phòng ngừa */}
          <tr>
            <td className='border border-black/50 px-2 py-1'>{data.row6?.loai || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row6?.gia || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row6?.sobuoi || '...'}</td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>{data.row7?.loai || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row7?.gia || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row7?.sobuoi || '...'}</td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>{data.row8?.loai || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row8?.gia || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row8?.sobuoi || '...'}</td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>{data.row9?.loai || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row9?.gia || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row9?.sobuoi || '...'}</td>
          </tr>

          <tr>
            <td colSpan={3} className='border border-black/50 px-2 py-1 text-center text-base font-bold'>
              Điều trị và phục hồi
            </td>
          </tr>

          {/* Điều trị và phục hồi */}
          <tr>
            <td className='border border-black/50 px-2 py-1'>{data.row10?.loai || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row10?.gia || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row10?.sobuoi || '...'}</td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>{data.row11?.loai || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row11?.gia || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row11?.sobuoi || '...'}</td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>{data.row12?.loai || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row12?.gia || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row12?.sobuoi || '...'}</td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>{data.row13?.loai || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row13?.gia || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row13?.sobuoi || '...'}</td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>{data.row14?.loai || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row14?.gia || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row14?.sobuoi || '...'}</td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>{data.row15?.loai || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row15?.gia || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row15?.sobuoi || '...'}</td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>{data.row16?.loai || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row16?.gia || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row16?.sobuoi || '...'}</td>
          </tr>

          <tr>
            <td colSpan={3} className='border border-black/50 px-2 py-1 text-center text-base font-bold'>
              Điều trị duy trì
            </td>
          </tr>

          {/* Điều trị duy trì */}
          <tr>
            <td className='border border-black/50 px-2 py-1'>{data.row17?.loai || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row17?.gia || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row17?.sobuoi || '...'}</td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>{data.row18?.loai || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row18?.gia || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row18?.sobuoi || '...'}</td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>{data.row19?.loai || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row19?.gia || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.row19?.sobuoi || '...'}</td>
          </tr>

          <tr>
            <td rowSpan={2} className='w-[70%] border border-black/50 px-2 py-1 text-center'>
              TỔNG CỘNG
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>Chi phí dự kiến</td>
            <td className='w-[2%] border border-black/50 px-2 py-1 text-center'>Số buổi dự kiến</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>{data.rowtotal?.gia || '...'}</td>
            <td className='border border-black/50 px-2 py-1'>{data.rowtotal?.sobuoi || '...'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Page6
