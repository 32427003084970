import React from 'react'
import { useSelector } from 'react-redux'

import Img1 from '@/modules/medical-record/assets/images/lamsang1.png'
import Img2 from '@/modules/medical-record/assets/images/lamsang2.png'
import Img3 from '@/modules/medical-record/assets/images/lamsang3.png'
import Mieng from '@/modules/medical-record/assets/images/trongmieng.png'
import type { RootState } from '@/store'

import type { MedicalFormState } from '../../common/constant'

const Page2: React.FC = () => {
  const data: MedicalFormState = useSelector((state: RootState) => state.medical.detail)

  if (!data) return <div>Không có dữ liệu</div>

  return (
    <div className='w-full'>
      <h3 className='text-center'>KHÁM LÂM SÀNG</h3>
      <h4>Khám ngoài mặt</h4>
      <div className='flex justify-center gap-4'>
        <img src={Img1} alt='' height={300} />
        <img src={Img2} alt='' height={300} />
        <img src={Img3} alt='' height={300} />
      </div>
      <span>Đánh dấu vào vị trí bất thường và mô tả (Nếu có)</span>
      <p>{data.ngoaimat || '...'}</p>

      <h4>Khám trong miệng</h4>
      <span>Đánh dấu vào vị trí bất thường và mô tả (Nếu có)</span>
      <div className='grid grid-cols-2 gap-2'>
        <img src={Mieng} alt='' className='w-full' />
        <p>{data.trongmieng || '...'}</p>
      </div>

      <h4>Khám cắn khớp</h4>
      <p>
        Xếp hạng Angle: Phải <span>{data?.cankhop?.anglephai || '-'}</span>
        <span className='ms-3'>Trái {data.cankhop?.angletrai || '-'}</span>
      </p>
      <p>
        Cắn phủ: <span>{data.cankhop?.canphu || '-'}</span> mm Cắn chìa: <span>{data.cankhop?.canchia || '-'}</span> mm
      </p>
      <p>
        Vận động há ngậm: Biên độ:
        <span> {data.cankhop?.biendo || '-'}</span> Độ lệch:
        <span> {data.cankhop?.dolech || '-'}</span> Tính chất:
        <span> {data.cankhop?.tinhchat || '-'}</span>
      </p>

      <table className='mt-4 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='border border-black/50 px-2 py-1 text-center font-bold'>Tiếng kêu khớp</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Thì há miệng</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Thì đóng miệng</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <span>{data.cankhop?.row1col1 || '-'}</span>
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <span>{data.cankhop?.row1col2 || '-'}</span>
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <span>{data.cankhop?.row1col3 || '-'}</span>
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1 text-center'>Khớp (P) </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <span>{data.cankhop?.row2col2 || '-'}</span>
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <span>{data.cankhop?.row2col3 || '-'}</span>
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1 text-center'>Khớp (T)</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <span>{data.cankhop?.row3col2 || '-'}</span>
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <span>{data.cankhop?.row3col3 || '-'}</span>
            </td>
          </tr>
        </tbody>
      </table>

      <p className='flex gap-1'>
        <span>Đường cong Spee:</span>
        <span>{data.congspee || ' '}</span>
      </p>
      <p className='flex gap-1'>
        <span>Đường cong Wilson:</span>
        <span>{data.congwilson || ' '}</span>
      </p>
      <p className='flex gap-1'>
        <span>Đặc điểm cắn khớp khác:</span>
        <span>{data.cankhopkhac || ' '}</span>
      </p>
    </div>
  )
}

export default Page2
