import React from 'react'
import { useSelector } from 'react-redux'

import type { RootState } from '@/store'

import type { MedicalFormState } from '../../common/constant'

const Page1: React.FC = () => {
  const data: MedicalFormState = useSelector((state: RootState) => state.medical.detail)

  return (
    <div className='form-medical'>
      <p className='text-lg font-bold'>KHOA RĂNG HÀM MẶT, ĐH Y Dược TP Hồ Chí Minh</p>
      <p className='mr-2'>Số phiếu điều trị: {data?.code}</p>
      <p className='mt-2'>Sinh viên theo dõi điều trị: {data?.student}</p>
      <h1 className='mt-6 text-center text-2xl font-bold'>THÔNG TIN BỆNH NHÂN</h1>

      <table className='mt-4 w-full table-fixed border-collapse'>
        <tbody>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Họ và Tên: {data?.patient?.name}</td>
            <td className='border border-black/50 px-2 py-1'>Ngày sinh: {data?.patient?.dob}</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Nghề nghiệp: {data?.patient?.job}</td>
            <td className='border border-black/50 px-2 py-1'>Trình độ văn hóa: {data?.patient?.education}</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Giới tính: {data?.patient?.gender}</td>
            <td className='border border-black/50 px-2 py-1'>Dân tộc/Quốc tịch: {data?.patient?.nation}</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Liên hệ (SĐT/Email/…): {data?.patient?.contact}</td>
            <td className='border border-black/50 px-2 py-1'>Nơi cư ngụ: {data?.patient?.address}</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Ngày khám đầu tiên: {data?.patient?.firstExam}</td>
            <td className='border border-black/50 px-2 py-1'>Lý do đến khám: {data?.patient?.reason}</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Người liên lạc trong trường hợp khẩn cấp: {data?.patient?.contactPerson}</td>
            <td className='border border-black/50 px-2 py-1'>Bệnh sử: {data?.patient?.history}</td>
          </tr>
        </tbody>
      </table>

      <p className='mt-6 font-bold'>Vấn đề toàn thân</p>
      <p className='mt-2'>Cân nặng: {data?.weight} kg</p>
      <p className='mt-2'>Chiều cao: {data?.height} cm</p>

      <table className='mt-4 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Dị ứng</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.sick?.diung ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Mang thai</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.sick?.mangthai ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Cao huyết áp</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.sick?.caohuyetap ? '☑' : '☐'}</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Bệnh bẩm sinh</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.sick?.bamsinh ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Cho con bú</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.sick?.choconbu ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Đái tháo đường</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.sick?.daithaoduong ? '☑' : '☐'}</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Hút thuốc lá</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.sick?.hutthuoc ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Từng bị ngất xỉu</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.sick?.bingatxiu ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Bệnh tim</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.sick?.benhtim ? '☑' : '☐'}</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Rượu bia</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.sick?.ruou ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Viêm khớp dạng thấp</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.sick?.viemkhop ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Bệnh máu</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.sick?.benhmau ? '☑' : '☐'}</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Mang máy điều hòa nhịp tim</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.sick?.maydieuhoa ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Viêm gan siêu vi A, B, C</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.sick?.viemgan ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Bệnh thận</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.sick?.benhthan ? '☑' : '☐'}</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Có stent mạch máu</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.sick?.stentmachmau ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Lao</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.sick?.lao ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Bệnh suyễn</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.sick?.benhsuyen ? '☑' : '☐'}</td>
          </tr>
        </tbody>
      </table>

      <p className='mt-6 font-bold'>Vấn đề tâm lý - hành vi - xã hội</p>
      <table className='mt-4 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Khó tiếp cận/ khó thuyết phục</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.tamly?.khotiepcan ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Yêu cầu thẩm mỹ thái quá</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.tamly?.yeucauthammy ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Vấn đề về tài chính</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.tamly?.vandetaichinh ? '☑' : '☐'}</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Khó hợp tác/không tuân thủ điều trị</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.tamly?.khohoptac ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Hành vi tự làm hại</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.tamly?.tulamhai ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Lo âu, sợ hãi</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.tamly?.loausohai ? '☑' : '☐'}</td>
          </tr>
        </tbody>
      </table>

      <p className='mt-6 font-bold'>Tiền sử nha khoa</p>
      <table className='mt-4 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='border border-black/50 px-2 py-1' colSpan={4}>
              Những lần khám răng gần đây, lý do: {data?.dental?.reasonHistory}
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1 font-bold'>Chăm sóc răng miệng</td>
            <td className='border border-black/50 px-2 py-1 text-center'> </td>
            <td className='border border-black/50 px-2 py-1'>Dùng tăm tre</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.dental?.dungtam ? '☑' : '☐'}</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Khám răng miệng định kỳ</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.dental?.khamdinhky ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Dùng chỉ nha khoa</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.dental?.dungchi ? '☑' : '☐'}</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Chải răng hằng ngày</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.dental?.chaihangngay ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Dùng bàn chải kẽ</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.dental?.dungbanchai ? '☑' : '☐'}</td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1' colSpan={4}>
              Cách chải răng hiện nay (số lần, cách thức, loại kem đánh răng): {data?.dental?.cachchairang}
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1 font-bold'>Chế độ ăn (tần suất)</td>
            <td className='border border-black/50 px-2 py-1'> </td>
            <td className='border border-black/50 px-2 py-1'> </td>
            <td className='border border-black/50 px-2 py-1'> </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>Thường ăn thực phẩm cứng</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.dental?.anthucphamcung ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Thường ăn/uống thực phẩm chua</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.dental?.anthucphamchua ? '☑' : '☐'}</td>
          </tr>

          <tr className='h-[1px]'>
            <td className='border border-black/50 px-2 py-1'>Thường ăn/uống thực phẩm ngọt</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.dental?.anthucphamngot ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Thường uống nước uống có ga</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.dental?.uongnuocgacoga ? '☑' : '☐'}</td>
          </tr>
        </tbody>
      </table>

      <p className='mt-6 font-bold'>Thói quen liên quan tình trạng răng miệng</p>
      <table className='mt-4 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Nhai một bên (phải / trái)</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.thoiquenrang?.nhaimotben ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Cắn môi/ má</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.thoiquenrang?.canmoi ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Nghiến răng khi ngủ</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.thoiquenrang?.nghienrangkhingu ? '☑' : '☐'}</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Nhai vật cứng</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.thoiquenrang?.nhaivatcung ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Tật đẩy lưỡi</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.thoiquenrang?.tatdayluoi ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Nghiến chặt răng khi thức</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.thoiquenrang?.nghienchatrang ? '☑' : '☐'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Page1
