import { Button, Form, Input, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import type { RootState } from '@/store'

import { addMember, fetchDepartments } from '../../reducers/doctorReducer'

const AddMember: React.FC = () => {
  const dispatch = useDispatch<any>()
  const navigate: NavigateFunction = useNavigate()
  const [form] = Form.useForm()
  const departments = useSelector((state: RootState) => state.doctor.departments)
  const currentDepartmentId = useSelector((state: RootState) => state.auth.user?.doctor?.departmentId)
  const currentDepartment = departments.find((department: any) => department.id === currentDepartmentId)
  const [loading, setLoading] = useState(false)

  const handleFinish = async (values: any) => {
    setLoading(true)
    try {
      await dispatch(addMember({ ...values, departmentId: Number(currentDepartmentId) })).unwrap()
      message.success('Bác sĩ đã được tạo thành công')
      form.resetFields()
      setTimeout(() => {
        navigate('/doctors')
      }, 2000)
    } catch (error) {
      message.error('Đã xảy ra lỗi khi tạo bác sĩ')
    } finally {
      setLoading(false)
    }
  }

  const fetchAllDepartments = async () => {
    try {
      await dispatch(fetchDepartments())
    } catch (error) {
      message.error('Đã xảy ra lỗi khi tải danh sách phòng ban')
    }
  }

  useEffect(() => {
    fetchAllDepartments()
    form.setFieldsValue({ departmentName: currentDepartment?.name })
  }, [])

  return (
    <div className='container mx-auto p-4'>
      <Form form={form} onFinish={handleFinish} layout='vertical'>
        <div className='mb-4 flex items-center justify-between'>
          <h1 className='m-0 text-2xl font-bold'>Tạo Bác Sĩ</h1>
          <Form.Item className='mb-0'>
            <Button type='primary' htmlType='submit' loading={loading}>
              Tạo Bác Sĩ
            </Button>
          </Form.Item>
        </div>
        <Form.Item name='name' label='Tên Bác Sĩ' rules={[{ required: true, message: 'Vui lòng nhập tên bác sĩ' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='username' label='Username' rules={[{ required: true, message: 'Vui lòng nhập Username' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='password' label='Password' rules={[{ required: true, message: 'Vui lòng nhập Password' }]}>
          <Input.Password />
        </Form.Item>
        <Form.Item name='specialization' label='Chuyên Khoa'>
          <Input />
        </Form.Item>
        <Form.Item name='phoneNumber' label='Số Điện Thoại'>
          <Input />
        </Form.Item>
        <Form.Item
          name='email'
          label='Email'
          rules={[
            { type: 'email', message: 'Email không hợp lệ' },
            { required: true, message: 'Vui lòng nhập Email' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name='departmentName' label='Phòng Ban'>
          <Input disabled />
        </Form.Item>
      </Form>
    </div>
  )
}

export default AddMember
