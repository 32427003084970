import { Radio } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'

import type { RootState } from '@/store'

import type { MedicalFormState } from '../../common/constant'

const Page10: React.FC = () => {
  const data: MedicalFormState = useSelector((state: RootState) => state.medical.detail)

  if (!data) return <div>Không có dữ liệu</div>

  return (
    <div className='w-full'>
      <h3 className='text-center text-xl'>BẢNG TỰ NHẬN XÉT VỀ TÍNH CHUYÊN NGHIỆP</h3>

      <p>Thang đánh giá: (1)Không chấp nhận được, (2) Cần cải thiện, (3) Chấp nhận được, (4) Tốt, (5) Rất tốt</p>

      <h3 className='mb-0 text-xl'>GIAO TIẾP</h3>

      <table className='mt-3 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Giao tiếp lịch sự với bệnh nhân</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row1' value={data.giaotiep?.row1}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>

          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Thể hiện sự tôn trọng, dùng ngôn ngữ phù hợp với bệnh nhân</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row2' value={data.giaotiep?.row2}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>

          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Tạo được hợp tác tích cực từ bệnh nhân</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row3' value={data.giaotiep?.row3}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>

          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Lắng nghe phản ánh của bệnh nhân và giải thích rõ ràng, hợp lý</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row4' value={data.giaotiep?.row4}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>

          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Trao đổi rõ ràng, lịch sự với các sinh viên khác cùng tham gia điều trị</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row5' value={data.giaotiep?.row5}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>

          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Giao tiếp lịch sự, lễ phép với giảng viên, đàn anh</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row6' value={data.giaotiep?.row6}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>
        </tbody>
      </table>

      <div className='mt-4 flex'>
        <span>Kế hoạch cải thiện:</span>
        <span>{data.giaotiep?.note}</span>
      </div>

      {/* tôn trọng chăm sóc bệnh nhân */}
      <h3 className='mb-0 text-xl'>TÔN TRỌNG & CHĂM SÓC BỆNH NHÂN</h3>

      <table className='mt-3 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Ý thức và tôn trọng sự khác biệt cá nhân</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row1' value={data.tontrong?.row1}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>

          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Thể hiện đồng cảm với nỗi đau đớn, khó khăn của bệnh nhân</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row2' value={data.tontrong?.row2}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>

          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Đặt nhu cầu & quyền lợi của bệnh nhân trên nhu cầu của bản thân</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row3' value={data.tontrong?.row3}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>

          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Tôn trọng sự riêng tư, không bàn luận về đời tư bệnh nhân</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row4' value={data.tontrong?.row4}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>
        </tbody>
      </table>

      <div className='mt-4 flex'>
        <span>Kế hoạch cải thiện:</span>
        <span>{data.tontrong?.note}</span>
      </div>

      {/* đạo đức */}
      <h3 className='mb-0 text-xl'>ĐẠO ĐỨC & TINH THẦN TRÁCH NHIỆM</h3>

      <table className='mt-3 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Thể hiện sự trung thực, tin cậy trong công việc</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row1' value={data.daoduc?.row1}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>

          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Tinh thần học hỏi, kiên nhẫn vượt khó để hoàn thành công việc</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row2' value={data.daoduc?.row2}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>

          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Trung thực trong ứng xử với bệnh nhân, bạn bè, thầy cô</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row3' value={data.daoduc?.row3}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>

          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Chuẩn bị kiến thức, kỹ năng trước khi thực hiện trên bệnh nhân</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row4' value={data.daoduc?.row4}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>

          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Bảo vệ sức khỏe bệnh nhân, không làm hại do thiếu hiểu biết hoặc kỹ năng kém</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row5' value={data.daoduc?.row5}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>
        </tbody>
      </table>

      <div className='mt-4 flex'>
        <span>Kế hoạch cải thiện:</span>
        <span>{data.daoduc?.note}</span>
      </div>

      {/* thái độ */}
      <h3 className='mb-0 text-xl'>THÁI ĐỘ NGHIÊM TÚC & NỖ LỰC TIẾN BỘ</h3>

      <table className='mt-3 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>
              Hợp tác tích cực với bệnh nhân, cộng sự, thầy cô để xây dựng & hoàn tất kế hoạch điều trị tốt nhất có thể
            </td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row1' value={data.thaido?.row1}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>

          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Tham khảo tài liệu và ý kiến chuyên môn trong điều trị</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row2' value={data.thaido?.row2}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>

          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Chú ý theo dõi & động viên phần tự chăm sóc của bệnh nhân</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row3' value={data.thaido?.row3}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>

          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Tinh thần cầu thị lắng nghe & điều chỉnh khi có góp ý từ BN, cộng sự, thầy cô</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row4' value={data.thaido?.row4}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>

          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Tạo dựng và duy trì hình ảnh cá nhân phù hợp</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row5' value={data.thaido?.row5}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>

          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Động viên & hỗ trợ cộng sự khi làm việc chung</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row6' value={data.thaido?.row6}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>
        </tbody>
      </table>

      <div className='mt-4 flex'>
        <span>Kế hoạch cải thiện:</span>
        <span>{data.thaido?.note}</span>
      </div>

      {/* chuẩn bị */}
      <h3 className='mb-0 text-xl'>CHUẨN BỊ & THAO TÁC TRONG THỰC HÀNH</h3>

      <table className='mt-3 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Chuẩn bị đầy đủ & bố trí gọn gàng dụng cụ, vật liệu cần thiết trước mỗi buổi ĐT</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row1' value={data.chuanbi?.row1}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>

          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Thực hiện hồ sơ, giấy tờ đầy đủ, trung thực</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row2' value={data.chuanbi?.row2}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>

          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Thao tác gọn gàng, hợp lý</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row3' value={data.chuanbi?.row3}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>

          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Duy trì, đảm bảo khay làm việc và xung quanh gọn gàng, vệ sinh</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row4' value={data.chuanbi?.row4}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>

          <tr>
            <td className='w-2/3 border border-black/50 px-2 py-1'>Trả lại ghế và khu vực làm việc sạch sẽ sau điều trị</td>
            <td className='w-1/3 border border-black/50 px-2 py-1 text-center'>
              <Radio.Group name='row5' value={data.chuanbi?.row5}>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
                <Radio value='5'>5</Radio>
              </Radio.Group>
            </td>
          </tr>
        </tbody>
      </table>

      <div className='mt-4 flex'>
        <span>Kế hoạch cải thiện:</span>
        <span>{data.chuanbi?.note}</span>
      </div>
    </div>
  )
}

export default Page10
