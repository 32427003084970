import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import type { RootState } from '@/store'

import type { MedicalFormState } from '../../common/constant'
import { updateEditState } from '../../reducers/medicalReducer'

const Page6: React.FC = () => {
  const dispatch = useDispatch<any>()
  const dataState: MedicalFormState = useSelector((state: RootState) => state.medical.editState)
  const [data, setData] = useState<MedicalFormState>({
    ...dataState
  })

  const handleInputChange = (setter: any, field: any) => async (e: any) => {
    const { name, type, checked, value } = e.target

    const newValue = type === 'checkbox' ? checked : value

    await setter((prevState: any) => {
      if (field) {
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [name]: newValue
          }
        }
      }
      return {
        ...prevState,
        [name]: newValue
      }
    })
  }

  useEffect(() => {
    dispatch(updateEditState(data))
  }, [data, dispatch])

  return (
    <div className='w-full'>
      <div className='mt-3 flex items-center justify-between'>
        <div className='flex flex-col text-lg'>
          <span>ĐẠI HỌC Y DƯỢC TPHCM</span>
          <span className='font-bold'>KHOA RĂNG HÀM MẶT</span>
        </div>
        <div className='w-1/3'>
          <div className='flex'>
            <span>Họ tên bệnh nhân:</span>
            <input type='text' name='name' className='flex-1' value={data.patient.name} onChange={handleInputChange(setData, 'patient')} />
          </div>
          <div className='flex'>
            <span>Số hồ sơ:</span>
            <input type='text' className='flex-1' name='code' value={data.code} onChange={handleInputChange(setData, null)} />
          </div>
        </div>
      </div>
      <h3 className='mb-2 text-center'>KẾ HOẠCH ĐIỀU TRỊ</h3>

      <table className='mt-4 w-full border-collapse'>
        <tbody>
          <tr>
            <td colSpan={3} className='border border-black/50 px-2 py-1 text-center text-base font-bold'>
              Kiểm soát tổng trạng
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1 text-center'>Yếu tố toàn thân cần lưu ý</td>
            <td colSpan={2} className='border border-black/50 px-2 py-1 text-center'>
              Xử trí
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='luuy' className='w-full' value={data.row1.luuy} onChange={handleInputChange(setData, 'row1')} />
            </td>
            <td colSpan={2} className='border border-black/50 px-2 py-1'>
              <input type='text' name='xutri' className='w-full' value={data.row1.xutri} onChange={handleInputChange(setData, 'row1')} />
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='luuy' className='w-full' value={data.row2.luuy} onChange={handleInputChange(setData, 'row2')} />
            </td>
            <td colSpan={2} className='border border-black/50 px-2 py-1'>
              <input type='text' name='xutri' className='w-full' value={data.row2.xutri} onChange={handleInputChange(setData, 'row2')} />
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='luuy' className='w-full' value={data.row3.luuy} onChange={handleInputChange(setData, 'row3')} />
            </td>
            <td colSpan={2} className='border border-black/50 px-2 py-1'>
              <input type='text' name='xutri' className='w-full' value={data.row3.xutri} onChange={handleInputChange(setData, 'row3')} />
            </td>
          </tr>

          <tr>
            <td colSpan={3} className='border border-black/50 px-2 py-1 text-center text-base font-bold'>
              Điều trị khẩn
            </td>
          </tr>

          {/* Điều trị khẩn */}
          <tr>
            <td className='w-[70%] border border-black/50 px-2 py-1 text-center'>Loại hình điều trị</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Chi phí dự kiến</td>
            <td className='w-[10%] border border-black/50 px-2 py-1 text-center'>Số buổi dự kiến</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='loai' className='w-full' value={data.row4.loai} onChange={handleInputChange(setData, 'row4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gia' className='w-full' value={data.row4.gia} onChange={handleInputChange(setData, 'row4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='sobuoi' className='w-full' value={data.row4.sobuoi} onChange={handleInputChange(setData, 'row4')} />
            </td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='loai' className='w-full' value={data.row5.loai} onChange={handleInputChange(setData, 'row5')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gia' className='w-full' value={data.row5.gia} onChange={handleInputChange(setData, 'row5')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='sobuoi' className='w-full' value={data.row5.sobuoi} onChange={handleInputChange(setData, 'row5')} />
            </td>
          </tr>

          <tr>
            <td colSpan={3} className='border border-black/50 px-2 py-1 text-center text-base font-bold'>
              Kiểm soát bệnh & phòng ngừa
            </td>
          </tr>

          {/* Kiểm soát bệnh & phòng ngừa */}
          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='loai' className='w-full' value={data.row6.loai} onChange={handleInputChange(setData, 'row6')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gia' className='w-full' value={data.row6.gia} onChange={handleInputChange(setData, 'row6')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='sobuoi' className='w-full' value={data.row6.sobuoi} onChange={handleInputChange(setData, 'row6')} />
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='loai' className='w-full' value={data.row7.loai} onChange={handleInputChange(setData, 'row7')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gia' className='w-full' value={data.row7.gia} onChange={handleInputChange(setData, 'row7')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='sobuoi' className='w-full' value={data.row7.sobuoi} onChange={handleInputChange(setData, 'row7')} />
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='loai' className='w-full' value={data.row8.loai} onChange={handleInputChange(setData, 'row8')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gia' className='w-full' value={data.row8.gia} onChange={handleInputChange(setData, 'row8')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='sobuoi' className='w-full' value={data.row8.sobuoi} onChange={handleInputChange(setData, 'row8')} />
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='loai' className='w-full' value={data.row9.loai} onChange={handleInputChange(setData, 'row9')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gia' className='w-full' value={data.row9.gia} onChange={handleInputChange(setData, 'row9')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='sobuoi' className='w-full' value={data.row9.sobuoi} onChange={handleInputChange(setData, 'row9')} />
            </td>
          </tr>

          <tr>
            <td colSpan={3} className='border border-black/50 px-2 py-1 text-center text-base font-bold'>
              Điều trị và phục hồi
            </td>
          </tr>

          {/* Điều trị và phục hồi */}
          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='loai' className='w-full' value={data.row10.loai} onChange={handleInputChange(setData, 'row10')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gia' className='w-full' value={data.row10.gia} onChange={handleInputChange(setData, 'row10')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='sobuoi' className='w-full' value={data.row10.sobuoi} onChange={handleInputChange(setData, 'row10')} />
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='loai' className='w-full' value={data.row11.loai} onChange={handleInputChange(setData, 'row11')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gia' className='w-full' value={data.row11.gia} onChange={handleInputChange(setData, 'row11')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='sobuoi' className='w-full' value={data.row11.sobuoi} onChange={handleInputChange(setData, 'row11')} />
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='loai' className='w-full' value={data.row12.loai} onChange={handleInputChange(setData, 'row12')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gia' className='w-full' value={data.row12.gia} onChange={handleInputChange(setData, 'row12')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='sobuoi' className='w-full' value={data.row12.sobuoi} onChange={handleInputChange(setData, 'row12')} />
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='loai' className='w-full' value={data.row13.loai} onChange={handleInputChange(setData, 'row13')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gia' className='w-full' value={data.row13.gia} onChange={handleInputChange(setData, 'row13')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='sobuoi' className='w-full' value={data.row13.sobuoi} onChange={handleInputChange(setData, 'row13')} />
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='loai' className='w-full' value={data.row14.loai} onChange={handleInputChange(setData, 'row14')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gia' className='w-full' value={data.row14.gia} onChange={handleInputChange(setData, 'row14')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='sobuoi' className='w-full' value={data.row14.sobuoi} onChange={handleInputChange(setData, 'row14')} />
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='loai' className='w-full' value={data.row15.loai} onChange={handleInputChange(setData, 'row15')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gia' className='w-full' value={data.row15.gia} onChange={handleInputChange(setData, 'row15')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='sobuoi' className='w-full' value={data.row15.sobuoi} onChange={handleInputChange(setData, 'row15')} />
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='loai' className='w-full' value={data.row16.loai} onChange={handleInputChange(setData, 'row16')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gia' className='w-full' value={data.row16.gia} onChange={handleInputChange(setData, 'row16')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='sobuoi' className='w-full' value={data.row16.sobuoi} onChange={handleInputChange(setData, 'row16')} />
            </td>
          </tr>

          <tr>
            <td colSpan={3} className='border border-black/50 px-2 py-1 text-center text-base font-bold'>
              Điều trị duy trì
            </td>
          </tr>

          {/* Điều trị duy trì */}
          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='loai' className='w-full' value={data.row17.loai} onChange={handleInputChange(setData, 'row17')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gia' className='w-full' value={data.row17.gia} onChange={handleInputChange(setData, 'row17')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='sobuoi' className='w-full' value={data.row17.sobuoi} onChange={handleInputChange(setData, 'row17')} />
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='loai' className='w-full' value={data.row18.loai} onChange={handleInputChange(setData, 'row18')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gia' className='w-full' value={data.row18.gia} onChange={handleInputChange(setData, 'row18')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='sobuoi' className='w-full' value={data.row18.sobuoi} onChange={handleInputChange(setData, 'row18')} />
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='loai' className='w-full' value={data.row19.loai} onChange={handleInputChange(setData, 'row19')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gia' className='w-full' value={data.row19.gia} onChange={handleInputChange(setData, 'row19')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='sobuoi' className='w-full' value={data.row19.sobuoi} onChange={handleInputChange(setData, 'row19')} />
            </td>
          </tr>

          <tr>
            <td rowSpan={2} className='w-[70%] border border-black/50 px-2 py-1 text-center'>
              TỔNG CỘNG
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>Chi phí dự kiến</td>
            <td className='w-[2%] border border-black/50 px-2 py-1 text-center'>Số buổi dự kiến</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gia' className='w-full' value={data.rowtotal.gia} onChange={handleInputChange(setData, 'rowtotal')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='sobuoi' className='w-full' value={data.rowtotal.sobuoi} onChange={handleInputChange(setData, 'rowtotal')} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Page6
