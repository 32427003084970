import { Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import type { RootState } from '@/store'

import type { MedicalFormState } from '../../common/constant'
import { updateEditState } from '../../reducers/medicalReducer'

const { TextArea } = Input

const Page9: React.FC = () => {
  const dispatch = useDispatch<any>()
  const dataState: MedicalFormState = useSelector((state: RootState) => state.medical.editState)
  const [data, setData] = useState<MedicalFormState>({
    ...dataState
  })

  const handleInputChange = (setter: any, field: any) => async (e: any) => {
    const { name, type, checked, value } = e.target

    const newValue = type === 'checkbox' ? checked : value

    await setter((prevState: any) => {
      if (field) {
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [name]: newValue
          }
        }
      }
      return {
        ...prevState,
        [name]: newValue
      }
    })
  }

  useEffect(() => {
    dispatch(updateEditState(data))
  }, [data, dispatch])

  return (
    <div className='w-full'>
      <h3 className='text-xl'>TỔNG KẾT SAU ĐIỀU TRỊ</h3>

      <table className='mt-4 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='border border-black/50 px-2 py-1 text-center'>Số buổi hẹn dự kiến</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Số buổi hẹn thực tế</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Lý do phát sinh</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Xác nhận của mentor</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='row1col1' className='w-full' value={data.page9.row1col1} onChange={handleInputChange(setData, 'page9')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='row1col2' className='w-full' value={data.page9.row1col2} onChange={handleInputChange(setData, 'page9')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='row1col3' className='w-full' value={data.page9.row1col3} onChange={handleInputChange(setData, 'page9')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='row1col4' className='w-full' value={data.page9.row1col4} onChange={handleInputChange(setData, 'page9')} />
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1 text-center'>Tổng chi phí dự kiến</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Tổng chi phí thực tế</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Lý do phát sinh</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Xác nhận của mentor</td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='row2col1' className='w-full' value={data.page9.row2col1} onChange={handleInputChange(setData, 'page9')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='row2col2' className='w-full' value={data.page9.row2col2} onChange={handleInputChange(setData, 'page9')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='row2col3' className='w-full' value={data.page9.row2col3} onChange={handleInputChange(setData, 'page9')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='row2col4' className='w-full' value={data.page9.row2col4} onChange={handleInputChange(setData, 'page9')} />
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className='text-xl'>TỔNG KẾT SAU ĐIỀU TRỊ</h3>

      <div className='mt-3'>Các yếu tố ảnh hưởng đến kế hoạch điều trị đã được đưa ra:</div>
      <TextArea
        placeholder='Yếu tố ảnh hưởng ...'
        autoSize={{
          minRows: 3
        }}
        value={data.page9.note1}
        name='note1'
        onChange={handleInputChange(setData, 'page9')}
      />

      <div className='mt-3'>Những khó khăn/vấn đề dẫn đến thay đổi kế hoạch điều trị hay ảnh hưởng kết quả điều trị:</div>
      <TextArea
        placeholder='Khó khăn ...'
        autoSize={{
          minRows: 3
        }}
        value={data.page9.note2}
        name='note2'
        onChange={handleInputChange(setData, 'page9')}
      />

      <div className='mt-3'>Những kĩ năng mới học được/cải thiện được sau theo dõi điều trị ca này:</div>
      <TextArea
        placeholder='Kĩ năng mới học ...'
        autoSize={{
          minRows: 3
        }}
        value={data.page9.note3}
        name='note3'
        onChange={handleInputChange(setData, 'page9')}
      />

      <div className='mt-3'>Nếu được thay đổi việc điều trị, bạn muốn thay đổi:</div>
      <TextArea
        autoSize={{
          minRows: 3
        }}
        value={data.page9.note4}
        name='note4'
        onChange={handleInputChange(setData, 'page9')}
      />
    </div>
  )
}

export default Page9
