import { Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Img1 from '@/modules/medical-record/assets/images/lamsang1.png'
import Img2 from '@/modules/medical-record/assets/images/lamsang2.png'
import Img3 from '@/modules/medical-record/assets/images/lamsang3.png'
import Mieng from '@/modules/medical-record/assets/images/trongmieng.png'
import type { RootState } from '@/store'

import type { MedicalFormState } from '../../common/constant'
import { updateEditState } from '../../reducers/medicalReducer'

const { TextArea } = Input

const Page2: React.FC = () => {
  const dispatch = useDispatch<any>()
  const dataState: MedicalFormState = useSelector((state: RootState) => state.medical.editState)
  const [data, setData] = useState<MedicalFormState>({
    ...dataState
  })

  const handleInputChange = (setter: any, field: any) => async (e: any) => {
    const { name, type, checked, value } = e.target

    const newValue = type === 'checkbox' ? checked : value

    await setter((prevState: any) => {
      if (field) {
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [name]: newValue
          }
        }
      }
      return {
        ...prevState,
        [name]: newValue
      }
    })
  }

  useEffect(() => {
    dispatch(updateEditState(data))
  }, [data, dispatch])

  return (
    <div className='w-full'>
      <h3 className='text-center'>KHÁM LÂM SÀNG</h3>
      <h4>Khám ngoài mặt</h4>
      <div className='flex justify-center gap-4'>
        <img src={Img1} alt='' height={300} />
        <img src={Img2} alt='' height={300} />
        <img src={Img3} alt='' height={300} />
      </div>
      <span>Đánh dấu vào vị trí bất thường và mô tả (Nếu có)</span>
      <TextArea
        placeholder='Mô tả'
        autoSize={{
          minRows: 4
        }}
        value={data.ngoaimat}
        name='ngoaimat'
        onChange={handleInputChange(setData, null)}
      />
      <h4>Khám trong miệng</h4>
      <span>Đánh dấu vào vị trí bất thường và mô tả (Nếu có)</span>
      <div className='grid grid-cols-2 gap-2'>
        <img src={Mieng} alt='' className='w-full' />
        <TextArea
          placeholder='Mô tả'
          autoSize={{
            minRows: 4
          }}
          value={data.trongmieng}
          name='trongmieng'
          onChange={handleInputChange(setData, null)}
        />
      </div>

      <h4>Khám cắn khớp</h4>
      <p>
        Xếp hạng Angle: Phải <input type='text' name='anglephai' value={data.cankhop.anglephai} onChange={handleInputChange(setData, 'cankhop')} />
        Trái <input type='text' name='angletrai' value={data.cankhop.angletrai} onChange={handleInputChange(setData, 'cankhop')} />
      </p>
      <p>
        Cắn phủ: <input type='text' name='canphu' className='w-20' value={data.cankhop.canphu} onChange={handleInputChange(setData, 'cankhop')} /> mm Cắn chìa:{' '}
        <input type='text' name='canchia' className='w-20' value={data.cankhop.canchia} onChange={handleInputChange(setData, 'cankhop')} /> mm
      </p>
      <p>
        Vận động há ngậm: Biên độ:
        <input type='text' name='biendo' className='w-20' value={data.cankhop.biendo} onChange={handleInputChange(setData, 'cankhop')} />
        Độ lệch:
        <input type='text' name='dolech' className='w-20' value={data.cankhop.dolech} onChange={handleInputChange(setData, 'cankhop')} />
        Tính chất:
        <input type='text' name='tinhchat' className='w-20' value={data.cankhop.tinhchat} onChange={handleInputChange(setData, 'cankhop')} />
      </p>

      <table className='mt-4 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='border border-black/50 px-2 py-1 text-center font-bold'>Tiếng kêu khớp</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Thì há miệng</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Thì đóng miệng</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1 font-bold'>
              <input type='text' name='row1col1' className='w-full' value={data.cankhop.row1col1} onChange={handleInputChange(setData, 'cankhop')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='row1col2' className='w-full' value={data.cankhop.row1col2} onChange={handleInputChange(setData, 'cankhop')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='row1col3' className='w-full' value={data.cankhop.row1col3} onChange={handleInputChange(setData, 'cankhop')} />
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1 text-center'>Khớp (P) </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='row2col2' className='w-full' value={data.cankhop.row2col2} onChange={handleInputChange(setData, 'cankhop')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='row2col3' className='w-full' value={data.cankhop.row2col3} onChange={handleInputChange(setData, 'cankhop')} />
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1 text-center'>Khớp (T)</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='row3col2' className='w-full' value={data.cankhop.row3col2} onChange={handleInputChange(setData, 'cankhop')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='row3col3' className='w-full' value={data.cankhop.row3col3} onChange={handleInputChange(setData, 'cankhop')} />
            </td>
          </tr>
        </tbody>
      </table>

      <p className='flex'>
        <span>Đường cong Spee:</span>
        <input type='text' className='flex-1' name='congspee' value={data.congspee} onChange={handleInputChange(setData, null)} />
      </p>
      <p className='flex'>
        <span>Đường cong Wilson:</span>
        <input type='text' className='flex-1' name='congwilson' value={data.congwilson} onChange={handleInputChange(setData, null)} />
      </p>
      <p className='flex'>
        <span>Đặc điểm cắn khớp khác:</span>
        <input type='text' className='flex-1' name='cankhopkhac' value={data.cankhopkhac} onChange={handleInputChange(setData, null)} />
      </p>
    </div>
  )
}

export default Page2
