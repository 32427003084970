import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '@/common/api'

export interface Organization {
  data: any
}

const initialState: Organization = {
  data: {}
}

export const getOrganizationTree = createAsyncThunk('organization/getOrganizationTree', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get('department/organization')
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const organizationSlice = createSlice({
  name: 'organization',

  initialState,

  reducers: {},

  extraReducers: (_builder) => {}
})

// export const { setFilter } = organizationSlice.actions
export default organizationSlice.reducer
