import { Radio, Slider } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'

import type { RootState } from '@/store'

import type { MedicalFormState } from '../../common/constant'

const Page7: React.FC = () => {
  const data: MedicalFormState = useSelector((state: RootState) => state.medical.detail)

  if (!data) return <div>Không có dữ liệu</div>

  return (
    <div className='w-full'>
      <h3 className='text-center text-xl'>BẢNG CÂU HỎI DÀNH CHO BỆNH NHÂN</h3>
      <p>Ông/bà vui lòng trả lời để sinh viên và Khoa Răng Hàm Mặt - ĐHYDTPHCM có thể chăm sóc tốt hơn cho bệnh nhân. Xin trân trọng cảm ơn.</p>

      <h4 className='text-lg'>Trước điều trị</h4>
      <p className='mb-2 pt-5'>Theo ông/bà, sức khoẻ răng miệng có ảnh hưởng đến sức khoẻ toàn thân như thế nào?</p>
      <div className='px-10'>
        <Slider
          className='custom-slider'
          min={1}
          max={5}
          step={1}
          dots
          value={data.ques1}
          marks={{
            1: 'Không liên quan',
            3: 'Bình thường',
            5: 'Rất liên quan'
          }}
        />
      </div>

      <p className='mb-2 pt-5'>Ông/Bà có thấy lo sợ khi ngồi trên ghế nha khoa không:</p>
      <div className='px-10'>
        <Slider
          className='custom-slider'
          min={1}
          max={5}
          step={1}
          dots
          value={data.ques2}
          marks={{
            1: 'Hoàn toàn không',
            2: 'Chỉ hơi hơi',
            3: 'Khá lo',
            4: 'Rất lo',
            5: 'Cực kì lo lắng'
          }}
        />
      </div>

      <div className='flex gap-1'>
        <span>Ông/Bà thấy sợ nhất loại hình điều trị nào trong nha khoa:</span>
        <span>{data?.ques3 || 'Không có'}</span>
      </div>

      <div className='mt-8 flex items-center gap-4'>
        <span>Ông/Bà đã từng điều trị tại khu điều trị của chúng tôi trước đây:</span>
        <Radio.Group name='ques4' value={data.ques4}>
          <Radio value='yes'>Có</Radio>
          <Radio value='no'>Không</Radio>
        </Radio.Group>
      </div>

      <p className='mb-2 pt-5'>Ông/Bà có trải nghiệm tốt với điều trị nha khoa trước đây không?</p>
      <div className='px-10'>
        <Slider
          className='custom-slider'
          min={1}
          max={5}
          step={1}
          dots
          value={data.ques5}
          marks={{
            1: 'Thất vọng',
            5: 'Rất hài lòng'
          }}
        />
      </div>

      <div className='mt-4 flex gap-1'>
        <span>Vui lòng mô tả rõ hơn:</span>
        <span>{data?.ques6}</span>
      </div>

      <div className='mt-4 flex'>
        <span>Ông/Bà có muốn cung cấp thêm thông tin gì cho bác sĩ không?</span>
        <span>{data?.ques7}</span>
      </div>

      <div className='mt-4 flex justify-end gap-1'>
        <span>Ngày:</span>
        <span>{data?.datepage7first || '___'}</span>
      </div>

      <h4 className='text-lg'>Sau điều trị, khảo sát mức độ hài lòng của bệnh nhân</h4>

      <p className='mb-2 pt-5'>Về kỹ năng giao tiếp và thái độ ứng xử của sinh viên điều trị:</p>
      <div className='px-10'>
        <Slider
          className='custom-slider'
          min={1}
          max={5}
          step={1}
          dots
          value={data.ques21}
          marks={{
            1: 'Không hài lòng',
            5: 'Rất hài lòng'
          }}
        />
      </div>

      <p className='mb-2 pt-5'>Về tính chuyên nghiệp, đảm bảo đúng tiến độ điều trị, đúng hẹn của sinh viên điều trị:</p>
      <div className='px-10'>
        <Slider
          className='custom-slider'
          min={1}
          max={5}
          step={1}
          dots
          value={data.ques22}
          marks={{
            1: 'Không hài lòng',
            5: 'Rất hài lòng'
          }}
        />
      </div>

      <p className='mb-2 pt-5'>Về kỹ năng chuyên môn của sinh viên điều trị</p>
      <div className='px-10'>
        <Slider
          className='custom-slider'
          min={1}
          max={5}
          step={1}
          dots
          value={data.ques23}
          marks={{
            1: 'Không hài lòng',
            5: 'Rất hài lòng'
          }}
        />
      </div>

      <p className='mb-2 pt-5'>Về kết quả điều trị sau cùng</p>
      <div className='px-10'>
        <Slider
          className='custom-slider'
          min={1}
          max={5}
          step={1}
          dots
          value={data.ques24}
          marks={{
            1: 'Không hài lòng',
            5: 'Rất hài lòng'
          }}
        />
      </div>

      <div className='mt-4 flex items-center gap-4'>
        <span>Ông/Bà có thấy giảm lo lắng sau khi được điều trị nha khoa không?</span>
        <Radio.Group name='ques25' value={data.ques25}>
          <Radio value='yes'>Có</Radio>
          <Radio value='no'>Không</Radio>
        </Radio.Group>
      </div>

      <p className='mb-2 pt-5'>Ông/Bà vui lòng đánh giá mức độ lo lắng với điều trị nha khoa hiện tại:</p>
      <div className='px-10'>
        <Slider
          className='custom-slider'
          min={1}
          max={5}
          step={1}
          dots
          value={data.ques26}
          marks={{
            1: 'Hoàn toàn không',
            2: 'Chỉ hơi hơi',
            3: 'Khá lo',
            4: 'Rất lo',
            5: 'Cực kì lo lắng'
          }}
        />
      </div>

      <p className='pt-4'>Góp ý của bệnh nhân: {data?.benhnhangopy || 'Không có'}</p>

      <div className='mt-4 flex justify-end gap-1'>
        <span>Ngày:</span>
        <span>{data?.datepage7second || '___'}</span>
      </div>
    </div>
  )
}

export default Page7
