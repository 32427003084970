import React from 'react'
import { useSelector } from 'react-redux'

import Rang from '@/modules/medical-record/assets/images/rang.png'
import type { RootState } from '@/store'

import type { MedicalFormState } from '../../common/constant'

const Page3: React.FC = () => {
  const data: MedicalFormState = useSelector((state: RootState) => state.medical.detail)

  if (!data) return <div>Không có dữ liệu</div>

  return (
    <div className='w-full'>
      <table className='mt-4 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='border border-black/50 px-2 py-1 text-center font-bold'>Khám tiếp xúc răng</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Tiếp xúc bên làm việc</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Tiếp xúc bên không làm việc</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1 text-center'>Đưa hàm sang (P)</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data.tiepxucrang?.row1col2 || '-'}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data.tiepxucrang?.row1col3 || '-'}</td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1 text-center'>Đưa hàm sang (T)</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data.tiepxucrang?.row2col2 || '-'}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data.tiepxucrang?.row2col3 || '-'}</td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1 text-center'>Đưa hàm ra trước</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data.tiepxucrang?.row3col2 || '-'}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data.tiepxucrang?.row3col3 || '-'}</td>
          </tr>
        </tbody>
      </table>

      <h4>Khám răng</h4>
      <div className='relative text-center text-lg'>
        <img src={Rang} alt='Rang' />
        {/* 11 - 18 */}
        <span className='absolute left-[20%] top-[5px]'>{data?.rang11}</span>
        <span className='absolute left-[20%] top-[39px]'>{data?.rang12}</span>
        <span className='absolute left-[20%] top-[73px]'>{data?.rang13}</span>
        <span className='absolute left-[20%] top-[108px]'>{data?.rang14}</span>
        <span className='absolute left-[20%] top-[143px]'>{data?.rang15}</span>
        <span className='absolute left-[20%] top-[182px]'>{data?.rang16}</span>
        <span className='absolute left-[20%] top-[222px]'>{data?.rang17}</span>
        <span className='absolute left-[20%] top-[258px]'>{data?.rang18}</span>
        {/* 21 - 28 */}
        <span className='absolute left-[75%] top-[5px]'>{data?.rang21}</span>
        <span className='absolute left-[75%] top-[39px]'>{data?.rang22}</span>
        <span className='absolute left-[75%] top-[73px]'>{data?.rang23}</span>
        <span className='absolute left-[75%] top-[108px]'>{data?.rang24}</span>
        <span className='absolute left-[75%] top-[143px]'>{data?.rang25}</span>
        <span className='absolute left-[75%] top-[182px]'>{data?.rang26}</span>
        <span className='absolute left-[75%] top-[222px]'>{data?.rang27}</span>
        <span className='absolute left-[75%] top-[258px]'>{data?.rang28}</span>
        {/* 48 - 41 */}
        <span className='absolute left-[20%] top-[321px]'>{data?.rang48}</span>
        <span className='absolute left-[20%] top-[363px]'>{data?.rang47}</span>
        <span className='absolute left-[20%] top-[402px]'>{data?.rang46}</span>
        <span className='absolute left-[20%] top-[441px]'>{data?.rang45}</span>
        <span className='absolute left-[20%] top-[477px]'>{data?.rang44}</span>
        <span className='absolute left-[20%] top-[510px]'>{data?.rang43}</span>
        <span className='absolute left-[20%] top-[547px]'>{data?.rang42}</span>
        <span className='absolute left-[20%] top-[580px]'>{data?.rang41}</span>
        {/* 38 - 31 */}
        <span className='absolute left-[75%] top-[321px]'>{data?.rang38}</span>
        <span className='absolute left-[75%] top-[361px]'>{data?.rang37}</span>
        <span className='absolute left-[75%] top-[402px]'>{data?.rang36}</span>
        <span className='absolute left-[75%] top-[442px]'>{data?.rang35}</span>
        <span className='absolute left-[75%] top-[476px]'>{data?.rang34}</span>
        <span className='absolute left-[75%] top-[510px]'>{data?.rang33}</span>
        <span className='absolute left-[75%] top-[547px]'>{data?.rang32}</span>
        <span className='absolute left-[75%] top-[580px]'>{data?.rang31}</span>
      </div>
      <p>
        Ghi trên sơ đồ răng bằng bút
        <strong> Đỏ</strong>: sâu/mòn răng/MT cần điều trị lại; .<strong>Xanh dương</strong>: trám tốt;
        <strong>Sọc đen</strong>: mão/cầu răng/veneer… Ghi <strong>I</strong>: implant; Đánh dấu chéo <strong>(X)</strong> cho răng mất hoặc chưa mọc. Mô tả cụ
        thể từng răng trên đường chấm (nếu cần)
      </p>

      <h3 className='text-center'>CẬN LÂM SÀNG</h3>
      <table className='mt-4 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='w-[45%] border border-black/50 px-2 py-1'>Phim quanh chóp</td>
            <td className='w-[5%] border border-black/50 px-2 py-1 text-center'>{data.canlamsang?.phimquanhkhop ? '☑' : '☐'}</td>
            <td className='w-[45%] border border-black/50 px-2 py-1'>Mẫu hàm</td>
            <td className='w-[5%] border border-black/50 px-2 py-1 text-center'>{data.canlamsang?.mauham ? '☑' : '☐'}</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Phim toàn cảnh</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data.canlamsang?.toancanh ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Xét nghiệm công thức máu</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data.canlamsang?.mau ? '☑' : '☐'}</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Phim cắn cánh</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data.canlamsang?.cancanh ? '☑' : '☐'}</td>
            <td className='border border-black/50 px-2 py-1'>Conebeam CT</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data.canlamsang?.conebeam ? '☑' : '☐'}</td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1' colSpan={4}>
              <div className='flex gap-1'>
                <span>Khác:</span>
                <span>{data.canlamsang?.khac}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div className='mt-3'>Kết quả/ nhận xét:</div>
      <p className='ps-4'>{data.canlamsang?.nhanxet || '...'}</p>
    </div>
  )
}

export default Page3
