import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '@/common/api'

export interface InfoState {
  item: any
}

const initialState: InfoState = {
  item: {}
}

export const getInfo = createAsyncThunk('info/getInfo', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get('infomation')
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const updateInfo = createAsyncThunk('info/updateInfo', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await api.put('infomation', payload)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const uploadFiles = createAsyncThunk('info/uploadFiles', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await api.post('infomation/upload', payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const removeFiles = createAsyncThunk('info/removeFiles', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await api.post('infomation/delete-files', payload)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const informationSlice = createSlice({
  name: 'information',

  initialState,

  reducers: {},

  extraReducers: (_builder) => {}
})

// export const { setFilter } = logSlice.actions
export default informationSlice.reducer
