/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import type { RootState } from '@/store'

import type { MedicalFormState } from '../../common/constant'
import { updateDataState } from '../../reducers/medicalReducer'

const Page8: React.FC = () => {
  const dispatch = useDispatch<any>()
  const dataState: MedicalFormState = useSelector((state: RootState) => state.medical.dataState)
  const [data, setData] = useState<MedicalFormState>({
    ...dataState
  })

  const handleInputChange = (setter: any, field: any) => async (e: any) => {
    const { name, type, value, checked } = e.target
    const newValue = type === 'checkbox' ? checked : value

    await setter((prevState: any) => {
      if (field) {
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [name]: newValue
          }
        }
      }
      return {
        ...prevState,
        [name]: newValue
      }
    })
  }

  useEffect(() => {
    dispatch(updateDataState(data))
  }, [data, dispatch])

  return (
    <div className='w-full'>
      <h3 className='text-center text-xl'>PHIẾU THEO DÕI ĐIỀU TRỊ</h3>

      <table className='mt-4 w-full border-collapse'>
        <tbody>
          <tr>
            <td width={10} className='border border-black/50 px-2 py-1 text-center font-bold'>
              STT /ngày
            </td>
            <td width={10} className='border border-black/50 px-2 py-1 text-center font-bold'>
              Ký hiệu răng/ hàm
            </td>
            <td className='border border-black/50 px-2 py-1 text-center font-bold'>NỘI DUNG THỰC HIỆN</td>
            <td className='border border-black/50 px-2 py-1 text-center font-bold'>SV điều trị</td>
            <td className='border border-black/50 px-2 py-1 text-center font-bold'>SV trợ thủ</td>
            <td width={150} className='border border-black/50 px-2 py-1 text-center font-bold'>
              Mentor đánh giá*
            </td>
          </tr>

          <tr>
            <td rowSpan={2} className='border border-black/50 px-2 py-1 text-center'>
              1
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='rang' className='w-full' value={data.p8row1.rang} onChange={handleInputChange(setData, 'p8row1')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='noidung' className='w-full' value={data.p8row1.noidung} onChange={handleInputChange(setData, 'p8row1')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svdieutri' className='w-full' value={data.p8row1.svdieutri} onChange={handleInputChange(setData, 'p8row1')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svtrothu' className='w-full' value={data.p8row1.svtrothu} onChange={handleInputChange(setData, 'p8row1')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='mentor' className='w-full' value={data.p8row1.mentor} onChange={handleInputChange(setData, 'p8row1')} />
            </td>
          </tr>
          <tr>
            <td colSpan={5} className='border border-black/50 px-2 py-1'>
              <div className='flex'>
                <span>Note**:</span>
                <input type='text' name='note' className='flex-1' value={data.p8row1.note} onChange={handleInputChange(setData, 'p8row1')} />
              </div>
            </td>
          </tr>

          <tr>
            <td rowSpan={2} className='border border-black/50 px-2 py-1 text-center'>
              2
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='rang' className='w-full' value={data.p8row2.rang} onChange={handleInputChange(setData, 'p8row2')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='noidung' className='w-full' value={data.p8row2.noidung} onChange={handleInputChange(setData, 'p8row2')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svdieutri' className='w-full' value={data.p8row2.svdieutri} onChange={handleInputChange(setData, 'p8row2')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svtrothu' className='w-full' value={data.p8row2.svtrothu} onChange={handleInputChange(setData, 'p8row2')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='mentor' className='w-full' value={data.p8row2.mentor} onChange={handleInputChange(setData, 'p8row2')} />
            </td>
          </tr>
          <tr>
            <td colSpan={5} className='border border-black/50 px-2 py-1'>
              <div className='flex'>
                <span>Note**:</span>
                <input type='text' name='note' className='flex-1' value={data.p8row2.note} onChange={handleInputChange(setData, 'p8row2')} />
              </div>
            </td>
          </tr>

          <tr>
            <td rowSpan={2} className='border border-black/50 px-2 py-1 text-center'>
              3
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='rang' className='w-full' value={data.p8row3.rang} onChange={handleInputChange(setData, 'p8row3')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='noidung' className='w-full' value={data.p8row3.noidung} onChange={handleInputChange(setData, 'p8row3')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svdieutri' className='w-full' value={data.p8row3.svdieutri} onChange={handleInputChange(setData, 'p8row3')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svtrothu' className='w-full' value={data.p8row3.svtrothu} onChange={handleInputChange(setData, 'p8row3')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='mentor' className='w-full' value={data.p8row3.mentor} onChange={handleInputChange(setData, 'p8row3')} />
            </td>
          </tr>
          <tr>
            <td colSpan={5} className='border border-black/50 px-2 py-1'>
              <div className='flex'>
                <span>Note**:</span>
                <input type='text' name='note' className='flex-1' value={data.p8row3.note} onChange={handleInputChange(setData, 'p8row3')} />
              </div>
            </td>
          </tr>

          <tr>
            <td rowSpan={2} className='border border-black/50 px-2 py-1 text-center'>
              4
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='rang' className='w-full' value={data.p8row4.rang} onChange={handleInputChange(setData, 'p8row4')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='noidung' className='w-full' value={data.p8row4.noidung} onChange={handleInputChange(setData, 'p8row4')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svdieutri' className='w-full' value={data.p8row4.svdieutri} onChange={handleInputChange(setData, 'p8row4')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svtrothu' className='w-full' value={data.p8row4.svtrothu} onChange={handleInputChange(setData, 'p8row4')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='mentor' className='w-full' value={data.p8row4.mentor} onChange={handleInputChange(setData, 'p8row4')} />
            </td>
          </tr>
          <tr>
            <td colSpan={5} className='border border-black/50 px-2 py-1'>
              <div className='flex'>
                <span>Note**:</span>
                <input type='text' name='note' className='flex-1' value={data.p8row4.note} onChange={handleInputChange(setData, 'p8row4')} />
              </div>
            </td>
          </tr>

          <tr>
            <td rowSpan={2} className='border border-black/50 px-2 py-1 text-center'>
              5
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='rang' className='w-full' value={data.p8row5.rang} onChange={handleInputChange(setData, 'p8row5')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='noidung' className='w-full' value={data.p8row5.noidung} onChange={handleInputChange(setData, 'p8row5')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svdieutri' className='w-full' value={data.p8row5.svdieutri} onChange={handleInputChange(setData, 'p8row5')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svtrothu' className='w-full' value={data.p8row5.svtrothu} onChange={handleInputChange(setData, 'p8row5')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='mentor' className='w-full' value={data.p8row5.mentor} onChange={handleInputChange(setData, 'p8row5')} />
            </td>
          </tr>
          <tr>
            <td colSpan={5} className='border border-black/50 px-2 py-1'>
              <div className='flex'>
                <span>Note**:</span>
                <input type='text' name='note' className='flex-1' value={data.p8row5.note} onChange={handleInputChange(setData, 'p8row5')} />
              </div>
            </td>
          </tr>

          <tr>
            <td rowSpan={2} className='border border-black/50 px-2 py-1 text-center'>
              6
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='rang' className='w-full' value={data.p8row6.rang} onChange={handleInputChange(setData, 'p8row6')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='noidung' className='w-full' value={data.p8row6.noidung} onChange={handleInputChange(setData, 'p8row6')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svdieutri' className='w-full' value={data.p8row6.svdieutri} onChange={handleInputChange(setData, 'p8row6')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svtrothu' className='w-full' value={data.p8row6.svtrothu} onChange={handleInputChange(setData, 'p8row6')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='mentor' className='w-full' value={data.p8row6.mentor} onChange={handleInputChange(setData, 'p8row6')} />
            </td>
          </tr>
          <tr>
            <td colSpan={5} className='border border-black/50 px-2 py-1'>
              <div className='flex'>
                <span>Note**:</span>
                <input type='text' name='note' className='flex-1' value={data.p8row6.note} onChange={handleInputChange(setData, 'p8row6')} />
              </div>
            </td>
          </tr>

          <tr>
            <td rowSpan={2} className='border border-black/50 px-2 py-1 text-center'>
              7
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='rang' className='w-full' value={data.p8row7.rang} onChange={handleInputChange(setData, 'p8row7')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='noidung' className='w-full' value={data.p8row7.noidung} onChange={handleInputChange(setData, 'p8row7')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svdieutri' className='w-full' value={data.p8row7.svdieutri} onChange={handleInputChange(setData, 'p8row7')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svtrothu' className='w-full' value={data.p8row7.svtrothu} onChange={handleInputChange(setData, 'p8row7')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='mentor' className='w-full' value={data.p8row7.mentor} onChange={handleInputChange(setData, 'p8row7')} />
            </td>
          </tr>
          <tr>
            <td colSpan={5} className='border border-black/50 px-2 py-1'>
              <div className='flex'>
                <span>Note**:</span>
                <input type='text' name='note' className='flex-1' value={data.p8row7.note} onChange={handleInputChange(setData, 'p8row7')} />
              </div>
            </td>
          </tr>

          <tr>
            <td rowSpan={2} className='border border-black/50 px-2 py-1 text-center'>
              8
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='rang' className='w-full' value={data.p8row8.rang} onChange={handleInputChange(setData, 'p8row8')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='noidung' className='w-full' value={data.p8row8.noidung} onChange={handleInputChange(setData, 'p8row8')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svdieutri' className='w-full' value={data.p8row8.svdieutri} onChange={handleInputChange(setData, 'p8row8')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svtrothu' className='w-full' value={data.p8row8.svtrothu} onChange={handleInputChange(setData, 'p8row8')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='mentor' className='w-full' value={data.p8row8.mentor} onChange={handleInputChange(setData, 'p8row8')} />
            </td>
          </tr>
          <tr>
            <td colSpan={5} className='border border-black/50 px-2 py-1'>
              <div className='flex'>
                <span>Note**:</span>
                <input type='text' name='note' className='flex-1' value={data.p8row8.note} onChange={handleInputChange(setData, 'p8row8')} />
              </div>
            </td>
          </tr>

          <tr>
            <td rowSpan={2} className='border border-black/50 px-2 py-1 text-center'>
              9
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='rang' className='w-full' value={data.p8row9.rang} onChange={handleInputChange(setData, 'p8row9')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='noidung' className='w-full' value={data.p8row9.noidung} onChange={handleInputChange(setData, 'p8row9')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svdieutri' className='w-full' value={data.p8row9.svdieutri} onChange={handleInputChange(setData, 'p8row9')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svtrothu' className='w-full' value={data.p8row9.svtrothu} onChange={handleInputChange(setData, 'p8row9')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='mentor' className='w-full' value={data.p8row9.mentor} onChange={handleInputChange(setData, 'p8row9')} />
            </td>
          </tr>
          <tr>
            <td colSpan={5} className='border border-black/50 px-2 py-1'>
              <div className='flex'>
                <span>Note**:</span>
                <input type='text' name='note' className='flex-1' value={data.p8row9.note} onChange={handleInputChange(setData, 'p8row9')} />
              </div>
            </td>
          </tr>

          <tr>
            <td rowSpan={2} className='border border-black/50 px-2 py-1 text-center'>
              10
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='rang' className='w-full' value={data.p8row10.rang} onChange={handleInputChange(setData, 'p8row10')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='noidung' className='w-full' value={data.p8row10.noidung} onChange={handleInputChange(setData, 'p8row10')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svdieutri' className='w-full' value={data.p8row10.svdieutri} onChange={handleInputChange(setData, 'p8row10')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svtrothu' className='w-full' value={data.p8row10.svtrothu} onChange={handleInputChange(setData, 'p8row10')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='mentor' className='w-full' value={data.p8row10.mentor} onChange={handleInputChange(setData, 'p8row10')} />
            </td>
          </tr>
          <tr>
            <td colSpan={5} className='border border-black/50 px-2 py-1'>
              <div className='flex'>
                <span>Note**:</span>
                <input type='text' name='note' className='flex-1' value={data.p8row10.note} onChange={handleInputChange(setData, 'p8row10')} />
              </div>
            </td>
          </tr>

          <tr>
            <td rowSpan={2} className='border border-black/50 px-2 py-1 text-center'>
              11
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='rang' className='w-full' value={data.p8row11.rang} onChange={handleInputChange(setData, 'p8row11')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='noidung' className='w-full' value={data.p8row11.noidung} onChange={handleInputChange(setData, 'p8row11')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svdieutri' className='w-full' value={data.p8row11.svdieutri} onChange={handleInputChange(setData, 'p8row11')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svtrothu' className='w-full' value={data.p8row11.svtrothu} onChange={handleInputChange(setData, 'p8row11')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='mentor' className='w-full' value={data.p8row11.mentor} onChange={handleInputChange(setData, 'p8row11')} />
            </td>
          </tr>
          <tr>
            <td colSpan={5} className='border border-black/50 px-2 py-1'>
              <div className='flex'>
                <span>Note**:</span>
                <input type='text' name='note' className='flex-1' value={data.p8row11.note} onChange={handleInputChange(setData, 'p8row11')} />
              </div>
            </td>
          </tr>

          <tr>
            <td rowSpan={2} className='border border-black/50 px-2 py-1 text-center'>
              12
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='rang' className='w-full' value={data.p8row12.rang} onChange={handleInputChange(setData, 'p8row12')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='noidung' className='w-full' value={data.p8row12.noidung} onChange={handleInputChange(setData, 'p8row12')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svdieutri' className='w-full' value={data.p8row12.svdieutri} onChange={handleInputChange(setData, 'p8row12')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svtrothu' className='w-full' value={data.p8row12.svtrothu} onChange={handleInputChange(setData, 'p8row12')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='mentor' className='w-full' value={data.p8row12.mentor} onChange={handleInputChange(setData, 'p8row12')} />
            </td>
          </tr>
          <tr>
            <td colSpan={5} className='border border-black/50 px-2 py-1'>
              <div className='flex'>
                <span>Note**:</span>
                <input type='text' name='note' className='flex-1' value={data.p8row12.note} onChange={handleInputChange(setData, 'p8row12')} />
              </div>
            </td>
          </tr>

          <tr>
            <td rowSpan={2} className='border border-black/50 px-2 py-1 text-center'>
              13
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='rang' className='w-full' value={data.p8row13.rang} onChange={handleInputChange(setData, 'p8row13')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='noidung' className='w-full' value={data.p8row13.noidung} onChange={handleInputChange(setData, 'p8row13')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svdieutri' className='w-full' value={data.p8row13.svdieutri} onChange={handleInputChange(setData, 'p8row13')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svtrothu' className='w-full' value={data.p8row13.svtrothu} onChange={handleInputChange(setData, 'p8row13')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='mentor' className='w-full' value={data.p8row13.mentor} onChange={handleInputChange(setData, 'p8row13')} />
            </td>
          </tr>
          <tr>
            <td colSpan={5} className='border border-black/50 px-2 py-1'>
              <div className='flex'>
                <span>Note**:</span>
                <input type='text' name='note' className='flex-1' value={data.p8row13.note} onChange={handleInputChange(setData, 'p8row13')} />
              </div>
            </td>
          </tr>

          <tr>
            <td rowSpan={2} className='border border-black/50 px-2 py-1 text-center'>
              14
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='rang' className='w-full' value={data.p8row14.rang} onChange={handleInputChange(setData, 'p8row14')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='noidung' className='w-full' value={data.p8row14.noidung} onChange={handleInputChange(setData, 'p8row14')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svdieutri' className='w-full' value={data.p8row14.svdieutri} onChange={handleInputChange(setData, 'p8row14')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svtrothu' className='w-full' value={data.p8row14.svtrothu} onChange={handleInputChange(setData, 'p8row14')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='mentor' className='w-full' value={data.p8row14.mentor} onChange={handleInputChange(setData, 'p8row14')} />
            </td>
          </tr>
          <tr>
            <td colSpan={5} className='border border-black/50 px-2 py-1'>
              <div className='flex'>
                <span>Note**:</span>
                <input type='text' name='note' className='flex-1' value={data.p8row14.note} onChange={handleInputChange(setData, 'p8row14')} />
              </div>
            </td>
          </tr>

          <tr>
            <td rowSpan={2} className='border border-black/50 px-2 py-1 text-center'>
              15
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='rang' className='w-full' value={data.p8row15.rang} onChange={handleInputChange(setData, 'p8row15')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='noidung' className='w-full' value={data.p8row15.noidung} onChange={handleInputChange(setData, 'p8row15')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svdieutri' className='w-full' value={data.p8row15.svdieutri} onChange={handleInputChange(setData, 'p8row15')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='svtrothu' className='w-full' value={data.p8row15.svtrothu} onChange={handleInputChange(setData, 'p8row15')} />
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='text' name='mentor' className='w-full' value={data.p8row15.mentor} onChange={handleInputChange(setData, 'p8row15')} />
            </td>
          </tr>
          <tr>
            <td colSpan={5} className='border border-black/50 px-2 py-1'>
              <div className='flex'>
                <span>Note**:</span>
                <input type='text' name='note' className='flex-1' value={data.p8row15.note} onChange={handleInputChange(setData, 'p8row15')} />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <span className='ms-2 italic'>*: Mentor cho điểm và nhận xét về quá trình làm việc, kết quả, thái độ… của SV trong từng buổi hẹn</span>
      <br />
      <span className='ms-2 italic'>
        ** Trong Notes, SV ghi nhận xét, chú thích, dẫn giải, điều học được… cho phần điều trị bên trên. Khi có những thay đổi so với kế hoạch điều trị ban đầu
        đã được ký đồng thuận, SV ghi vào Phiếu theo dõi ĐT và có chữ kí của mentor và bệnh nhân.
      </span>
    </div>
  )
}

export default Page8
