import { Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Rang from '@/modules/medical-record/assets/images/rang.png'
import type { RootState } from '@/store'

import type { MedicalFormState } from '../../common/constant'
import { updateDataState } from '../../reducers/medicalReducer'

const { TextArea } = Input

const Page3: React.FC = () => {
  const dispatch = useDispatch<any>()
  const dataState: MedicalFormState = useSelector((state: RootState) => state.medical.dataState)
  const [data, setData] = useState<MedicalFormState>({
    ...dataState
  })

  const handleInputChange = (setter: any, field: any) => async (e: any) => {
    const { name, type, checked, value } = e.target

    const newValue = type === 'checkbox' ? checked : value

    await setter((prevState: any) => {
      if (field) {
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [name]: newValue
          }
        }
      }
      return {
        ...prevState,
        [name]: newValue
      }
    })
  }

  useEffect(() => {
    dispatch(updateDataState(data))
  }, [data, dispatch])

  return (
    <div className='w-full'>
      <table className='mt-4 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='border border-black/50 px-2 py-1 text-center font-bold'>Khám tiếp xúc răng</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Tiếp xúc bên làm việc</td>
            <td className='border border-black/50 px-2 py-1 text-center'>Tiếp xúc bên không làm việc</td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1 text-center'>Đưa hàm sang (P)</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='row1col2' className='w-full' value={data.tiepxucrang.row1col2} onChange={handleInputChange(setData, 'tiepxucrang')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='row1col3' className='w-full' value={data.tiepxucrang.row1col3} onChange={handleInputChange(setData, 'tiepxucrang')} />
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1 text-center'>Đưa hàm sang (T)</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='row2col2' className='w-full' value={data.tiepxucrang.row2col2} onChange={handleInputChange(setData, 'tiepxucrang')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='row2col3' className='w-full' value={data.tiepxucrang.row2col3} onChange={handleInputChange(setData, 'tiepxucrang')} />
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1 text-center'>Đưa hàm ra trước</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='row3col2' className='w-full' value={data.tiepxucrang.row3col2} onChange={handleInputChange(setData, 'tiepxucrang')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='row3col3' className='w-full' value={data.tiepxucrang.row3col3} onChange={handleInputChange(setData, 'tiepxucrang')} />
            </td>
          </tr>
        </tbody>
      </table>

      <h4>Khám răng</h4>
      <div className='relative text-center'>
        <img src={Rang} alt='Rang' />
        {/* 11 - 18 */}
        <input type='text' name='rang11' className='absolute left-[15%] top-[8px]' value={data.rang11} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang12' className='absolute left-[15%] top-[43px]' value={data.rang12} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang13' className='absolute left-[15%] top-[78px]' value={data.rang13} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang14' className='absolute left-[15%] top-[113px]' value={data.rang14} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang15' className='absolute left-[15%] top-[150px]' value={data.rang15} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang16' className='absolute left-[15%] top-[187px]' value={data.rang16} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang17' className='absolute left-[15%] top-[226px]' value={data.rang17} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang18' className='absolute left-[15%] top-[265px]' value={data.rang18} onChange={handleInputChange(setData, null)} />
        {/* 21 - 28 */}
        <input type='text' name='rang21' className='absolute left-[70%] top-[9px]' value={data.rang21} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang22' className='absolute left-[70%] top-[45px]' value={data.rang22} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang23' className='absolute left-[70%] top-[80px]' value={data.rang23} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang24' className='absolute left-[70%] top-[114px]' value={data.rang24} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang25' className='absolute left-[70%] top-[149px]' value={data.rang25} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang26' className='absolute left-[70%] top-[191px]' value={data.rang26} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang27' className='absolute left-[70%] top-[227px]' value={data.rang27} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang28' className='absolute left-[70%] top-[265px]' value={data.rang28} onChange={handleInputChange(setData, null)} />
        {/* 48 - 41 */}
        <input type='text' name='rang48' className='absolute left-[15%] top-[327px]' value={data.rang48} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang47' className='absolute left-[15%] top-[367px]' value={data.rang47} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang46' className='absolute left-[15%] top-[407px]' value={data.rang46} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang45' className='absolute left-[15%] top-[447px]' value={data.rang45} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang44' className='absolute left-[15%] top-[482px]' value={data.rang44} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang43' className='absolute left-[15%] top-[515px]' value={data.rang43} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang42' className='absolute left-[15%] top-[554px]' value={data.rang42} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang41' className='absolute left-[15%] top-[585px]' value={data.rang41} onChange={handleInputChange(setData, null)} />
        {/* 38 - 31 */}
        <input type='text' name='rang38' className='absolute left-[70%] top-[326px]' value={data.rang38} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang37' className='absolute left-[70%] top-[366px]' value={data.rang37} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang36' className='absolute left-[70%] top-[407px]' value={data.rang36} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang35' className='absolute left-[70%] top-[447px]' value={data.rang35} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang34' className='absolute left-[70%] top-[482px]' value={data.rang34} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang33' className='absolute left-[70%] top-[515px]' value={data.rang33} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang32' className='absolute left-[70%] top-[554px]' value={data.rang32} onChange={handleInputChange(setData, null)} />
        <input type='text' name='rang31' className='absolute left-[70%] top-[585px]' value={data.rang31} onChange={handleInputChange(setData, null)} />
      </div>
      <p>
        Ghi trên sơ đồ răng bằng bút
        <strong> Đỏ</strong>: sâu/mòn răng/MT cần điều trị lại; .<strong>Xanh dương</strong>: trám tốt;
        <strong>Sọc đen</strong>: mão/cầu răng/veneer… Ghi <strong>I</strong>: implant; Đánh dấu chéo <strong>(X)</strong> cho răng mất hoặc chưa mọc. Mô tả cụ
        thể từng răng trên đường chấm (nếu cần)
      </p>

      <h3 className='text-center'>CẬN LÂM SÀNG</h3>
      <table className='mt-4 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='w-[45%] border border-black/50 px-2 py-1'>Phim quanh chóp</td>
            <td className='w-[5%] border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='phimquanhkhop' checked={data.canlamsang.phimquanhkhop} onChange={handleInputChange(setData, 'canlamsang')} />
            </td>
            <td className='w-[45%] border border-black/50 px-2 py-1'>Mẫu hàm</td>
            <td className='w-[5%] border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='mauham' checked={data.canlamsang.mauham} onChange={handleInputChange(setData, 'canlamsang')} />
            </td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Phim toàn cảnh</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='toancanh' checked={data.canlamsang.toancanh} onChange={handleInputChange(setData, 'canlamsang')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Xét nghiệm công thức máu</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='mau' checked={data.canlamsang.mau} onChange={handleInputChange(setData, 'canlamsang')} />
            </td>
          </tr>
          <tr>
            <td className='border border-black/50 px-2 py-1'>Phim cắn cánh</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='cancanh' checked={data.canlamsang.cancanh} onChange={handleInputChange(setData, 'canlamsang')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>Conebeam CT</td>
            <td className='border border-black/50 px-2 py-1 text-center'>
              <input type='checkbox' name='conebeam' checked={data.canlamsang.conebeam} onChange={handleInputChange(setData, 'canlamsang')} />
            </td>
          </tr>

          <tr>
            <td className='border border-black/50 px-2 py-1' colSpan={4}>
              <div className='flex'>
                <span>Khác:</span>
                <input type='text' name='khac' className='flex-1' value={data.canlamsang.khac} onChange={handleInputChange(setData, 'canlamsang')} />
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div className='mt-3'>Kết quả/ nhận xét:</div>
      <TextArea
        placeholder='Thêm mô tả chi tiết'
        autoSize={{
          minRows: 4
        }}
        value={data.canlamsang.nhanxet}
        name='nhanxet'
        onChange={handleInputChange(setData, 'canlamsang')}
      />
    </div>
  )
}

export default Page3
