import React from 'react'

import MedicalList from './MedicalList'
import AddLayout from './partials/create/AddLayout'
import DetailLayout from './partials/detail/DetailLayout'
import EditLayout from './partials/edit/EditLayout'

export const MedicalRoutes = [
  {
    path: 'medical-records',
    element: <MedicalList />
  },
  {
    path: 'medical-records/add-medical',
    element: <AddLayout />
  },
  {
    path: 'medical-records/:id',
    element: <DetailLayout />
  },
  {
    path: 'medical-records/:id/edit',
    element: <EditLayout />
  }
]
