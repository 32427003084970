import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '@/common/api'
import type { RootState } from '@/store'

export interface IState {
  items: any[]
  total: number
  query?: string
}

const initialState: IState = {
  items: [],
  total: 0
}

export const getListUser = createAsyncThunk('users/getAll', async (_, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState
    const { query } = state.users

    const params: any = {}

    if (query && query.trim() !== '') {
      params.q = query
    }

    const response = await api.get('user/all', { params })
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const disableUser = createAsyncThunk('users/disable', async (id: number, { rejectWithValue }) => {
  try {
    const response = await api.put(`user/disable/${id}`)
    if (response) {
      return id
    }
    return 0
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const activateUser = createAsyncThunk('users/activate', async (id: number, { rejectWithValue }) => {
  try {
    const response = await api.put(`user/activate/${id}`)
    if (response) {
      return id
    }
    return 0
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const changeRole = createAsyncThunk('users/changeRole', async (id: any, { rejectWithValue }) => {
  try {
    const response = await api.put(`user/change-role/${id}`)
    if (response) {
      return {
        id,
        role: response
      }
    }
    return -1
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

type ResetPassword = {
  id: number
  password: string
}

export const resetPassword = createAsyncThunk('users/resetPassword', async (data: ResetPassword, { rejectWithValue }) => {
  try {
    const { id, password } = data
    const response = await api.put(`user/reset-password/${id}`, {
      password
    })
    if (response) {
      return data.id
    }
    return false
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const slice = createSlice({
  name: 'users',

  initialState,

  reducers: {
    setFilter: (state, action) => {
      state.query = action.payload
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getListUser.fulfilled, (state, action) => {
        const { total, items } = action.payload as any
        return {
          ...state,
          total,
          items
        }
      })
      .addCase(disableUser.fulfilled, (state, action) => {
        const id = action.payload as number
        const index = state.items.findIndex((item) => item.id === id)
        state.items[index].isDel = !state.items[index].isDel
      })
      .addCase(activateUser.fulfilled, (state, action) => {
        const id = action.payload as number
        const index = state.items.findIndex((item) => item.id === id)
        state.items[index].isDel = !state.items[index].isDel
      })
      .addCase(changeRole.fulfilled, (state, action) => {
        const { id, role } = action.payload as any
        const index = state.items.findIndex((item) => item.id === id)
        state.items[index].role = role
      })
  }
})
export const { setFilter } = slice.actions
export default slice.reducer
